(function () {
    angular.module('mobileApp').service('sensorDataLoaderService', ['$rootScope', '$window', '$timeout', '$http', '$cookies', '$sce','utilityProviderService', 'alertGeneratorService', 'reverseGeoCodeService', 'systemLoggerService', 'enterpriseHierarchyDataService',
        function ($rootScope, $window, $timeout, $http, $cookies,  $sce, utilityProviderService, alertGeneratorService, reverseGeoCodeService, systemLoggerService, enterpriseHierarchyDataService) {

            var encodedData = $window.encodedSensorData;
            var environment = $window.environment;
            var sensorData;
            var visitedInteraction = "Loaded";
            var shortUrl = $window.shortUrl;
            var url = window.location.href;
            $rootScope.globalUIBdatepickerFormat = "dd-MMM-yyyy";

            var getSensorData = function () {
                $rootScope.isWidget = isWidgetG;

                //Remove inconsistent userID cookie
                document.cookie = "userID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                if(isAnonymous){
                    $rootScope.userID = $cookies.get('userID');
                }else{
                    $rootScope.userID = serverSideUserID;
                }

                if(typeof $cookies.get('userID') == 'undefined'){
                    location.href = 'https://emojot.com/emojot_mobile/cookiereaderror.jsp?mobileSkin='+mobileSkinParam;
                }

                if (encodedData) {
                    try {
                        sensorData = JSON.parse(Base64.decode(encodedData));

                        if ($window.autoLoyaltySubmitted && $window.autoLoyaltySubmitUserID) {
                            var cookieStoredUserID = $cookies.get('userID');
                            if (cookieStoredUserID != $window.autoLoyaltySubmitUserID) {
                                utilityProviderService.setUserId($window.autoLoyaltySubmitUserID)
                            }

                            $rootScope.userID=$window.autoLoyaltySubmitUserID;
                        }

                        if ($window.autoLoyaltySubmitted) {
                            $rootScope.loyaltyRegistered = true;
                        }

                        if ($window.stateSetConfigBase64 != "null" && $window.stateSetConfigBase64 != "" && typeof $window.stateSetConfigBase64 != "undefined") {
                            $window.stateSetConfig = JSON.parse(Base64.decode($window.stateSetConfigBase64));
                        }

                        setSensorData(sensorData);
                        removeEngagement();
                        $rootScope.$broadcast('Sensor Data Loaded');
                        showContent();

                    } catch (e) {
                        console.log(e);
                    }
                }
                else {
                    performActivityCall(1);
                }
            };


            var getSurveyDraftKeyObject = function(){
                var draftKeyObj = {
                    sensorID :  $rootScope.activityID,
                    companyID: $rootScope.companyID,
                    userID : $rootScope.userID
                }
                if($rootScope.draftResponseOptions && $window.emoSignature){
                    var keyArray = $rootScope.draftResponseOptions['draftKey'];
                    if(keyArray && keyArray.length > 0){
                        keyArray.forEach(function(emoSignatureKey){
                            if($window.emoSignature.hasOwnProperty(emoSignatureKey)){
                                draftKeyObj.emoSig = draftKeyObj.emoSig || {};
                                draftKeyObj.emoSig[emoSignatureKey] = $window.emoSignature[emoSignatureKey]
                            }
                        })
                    }
                }
                return draftKeyObj;
            }
            var getSurveyDraftKey = function(action, offline){
                var storageKey = "surveydraft_"+$rootScope.companyID + "-" + $rootScope.activityID;
                if(offline){
                    storageKey += "-" + $rootScope.userID
                }
                if($rootScope.draftResponseOptions && $window.emoSignature){
                    var keyArray = $rootScope.draftResponseOptions['draftKey'];
                    var signatureKey = "";
                    if(keyArray && keyArray.length > 0){
                        keyArray.forEach(function(emoSignatureKey){
                            if($window.emoSignature.hasOwnProperty(emoSignatureKey)){
                                signatureKey += "__"+emoSignatureKey + "~~"+$window.emoSignature[emoSignatureKey]
                            }
                        })

                        if(action == "get"){
                            //check whether draft contain without emoSig
                            if(!localStorage.getItem(storageKey+signatureKey) && localStorage.getItem(storageKey)){
                                return storageKey;
                            } else{
                                return storageKey +signatureKey;
                            }
                        } else {
                            return storageKey+signatureKey;
                        }
                    }
                }
                return storageKey;
            }
            $rootScope.draftResponseObj;
            $rootScope.localDraftResponseObj;
            $rootScope.deviceSavedResponseFound = false;
            var loadDraftResponse = function(callback){

                if(window.emoteRestricted){
                    callback(false);
                    return;
                }

                $rootScope.draftResponseObj = null;
                $rootScope.localDraftResponseObj = null;
                if($rootScope.loyaltyFields.length == 0 || ($rootScope.doKIOSK && !$rootScope.captureLoyaltyFirst) || !$rootScope.draftResponse){
                    callback(false);
                    return;
                }
                var draftObject = getSurveyDraftKeyObject();
                var tries = 0;
                $http({
                    method: 'GET',
                    url: 'https://sensorapi.emojot.com/v1/responseDraft',
                    params: draftObject,
                    contentType: 'application/json',
                    headers: {'x-api-key': $rootScope.sensorAPIKey}
                }).then(function (response) {
                    if(response && response.data && response.data.result && response.data.result.responseDraft){
                        $rootScope.draftResponseObj = response.data.result.responseDraft;
                    }
                    if(!$rootScope.draftResponseObj && (!$rootScope.draftResponseOptions || !$rootScope.draftResponseOptions.offlineDraft)){
                        var storageKey = getSurveyDraftKey("get", false);
                        $rootScope.draftResponseObj  = localStorage.getItem(storageKey);
                    }
                    if($rootScope.draftResponseOptions && $rootScope.draftResponseOptions.offlineDraft){
                        var storageKey = getSurveyDraftKey("get", true);
                        $rootScope.localDraftResponseObj  = localStorage.getItem(storageKey);
                    }
                    if($rootScope.draftResponseObj || $rootScope.localDraftResponseObj){
                        callback(true);
                    }else{
                        callback(false);
                    }
                }, function (status) {

                });

            }

            function setSensorData(sensorData, fromActivityCalll) {
                var sensorLogo = "https://storage.emojot.com/logos/sensor_logo/" + sensorData['companyID'] + '_' + sensorData['_id'] + ".png";
                $rootScope.randomizedSensor = false;
                showHideSensorPagesBasedOnEhFields(sensorData, $window.emoSignature, null, $window.matchingEmoSigRule)

                //general
                $rootScope.activityID = sensorData['_id'];
                $rootScope.companyID = sensorData['companyID'];
                $rootScope.activityTitle = sensorData['activityTitle'];
                $rootScope.activityURL = sensorData['activityURL'];
                $rootScope.sensorAPIKey = sensorData['sensorAPIKey'];
                
                if (!$rootScope.activityTitle) {
                    $rootScope.activityTitle = sensorData['activityName'];
                }else{
                    $rootScope.activityTitle = processSensorTitleWithEmoSignatures($rootScope.activityTitle);
                }
                $rootScope.companyLogo = sensorData['companyLogo'];

                if (sensorData['sensorLogo']) {
                    $rootScope.sensorLogo = sensorData['sensorLogo']['sensorLogoName'];
                }

                if ($rootScope.sensorLogo) {
                    sensorLogo = "https://storage.emojot.com/logos/sensor_logo/" + $rootScope.sensorLogo + ".png";
                    $rootScope.companyLogo = sensorLogo;
                } else {
                    verifyImageURL(sensorLogo, function (imageExists) {
                        if (imageExists === true) {
                            $rootScope.companyLogo = sensorLogo;
                        } else {
                            $rootScope.companyLogo = sensorData['companyLogo'];
                        }
                    });
                }



                $rootScope.activityType = sensorData['activityType'];
                $rootScope.isHideUIComponent = sensorData['hideUIComponent'];
                $rootScope.hideUIComponents = sensorData['uiComponents'];
                $rootScope.doKIOSK = sensorData['doKIOSK'];
                $rootScope.commonOAuthTokenAllowed = sensorData['commonOAuthTokenAllowed'];

                if(!$rootScope.doKIOSK&& $window.doKIOSKRequest){
                    $rootScope.doKIOSK=true;
                }

                if($rootScope.doKIOSK&& !$window.doKIOSKRequest){
                    $rootScope.doKIOSK=false;
                }

                $rootScope.loyaltyFields = sensorData['loyaltyFields'];
                if($rootScope.loyaltyFields && $rootScope.loyaltyFields.length > 0){
                    $rootScope.captureLoyaltyFirst = sensorData['captureLoyaltyFirst'];
                    $rootScope.loyaltyMessage =  $sce.trustAsHtml(sensorData['loyaltyMessage']);
                    $rootScope.loyaltyPointsPerCard = sensorData['loyaltyPointsPerCard'];
                    $rootScope.loyaltyPointsPerRow = sensorData['loyaltyPointsPerRow'];
                }

                if ($rootScope.doKIOSK) {
                    $rootScope.userID = utilityProviderService.reGenerateNewUserID();
                }

                $rootScope.draftResponse = false;
                if(angular.isDefined(sensorData['draftResponse']) && sensorData['draftResponse'] && !$rootScope.automaticEmotingEnabled){
                    $rootScope.draftResponse = sensorData['draftResponse'];
                }

                $rootScope.draftResponseOptions = null;
                if(angular.isDefined(sensorData['draftResponseOptions']) && sensorData['draftResponseOptions']){
                    $rootScope.draftResponseOptions = sensorData['draftResponseOptions'];
                }

                if($rootScope.draftResponse && !$rootScope.doKIOSK && !$rootScope.captureLoyaltyFirst && !fromActivityCalll){
                    loadDraftResponse(function(draftFound){
                        if(draftFound){
                            $('#draftPopulateConfirmation').modal('show');
                        }
                        if(sensorData.activityType == "campaign"){
                            var randomizedSensorGroups = getSensorWithRandomizedQuestions(sensorData.groups);
                            $rootScope.randomizedSensor = randomizedSensorGroups[0];
                        }
                    })
                }else{
                    if(sensorData.activityType == "campaign"){
                        var randomizedSensorGroups = getSensorWithRandomizedQuestions(sensorData.groups);
                        $rootScope.randomizedSensor = randomizedSensorGroups[0];
                    }
                }
                $rootScope.advancedGeoLocationEnabled = sensorData['emoterAdvancedLocEnabled'];
                $rootScope.kioskImageURL = sensorData['kioskImageURL'];
                if (!$rootScope.kioskImageURL) {
                    $rootScope.kioskImgShow = false;
                } else {
                    $rootScope.kioskImgShow = true;
                }

                if(sensorData['resetTimeoutForIdling']){
                    $rootScope.resetTimeoutForIdling = sensorData['resetTimeoutForIdling'] === 'none'? 0 : Math.ceil(parseFloat(sensorData['resetTimeoutForIdling']) * 60 * 1000); //convert to ms
                }

                if ($window.userVisibleName) {
                    $rootScope.userVisibleName = $window.userVisibleName;
                    $rootScope.visibleNameForEmot = $window.userVisibleName;
                } else {
                    $rootScope.userVisibleName = "Anonymous";
                    $rootScope.visibleNameForEmot = "Anonymous";
                }

                $rootScope.isCapturePicture = sensorData['capturePicture'];
                $rootScope.responderImageCapture = sensorData['responderImageCapture'];
                $rootScope.isSMSharing = sensorData['SMSharingEnabled'];
                $rootScope.hashTags = sensorData['hashTags'];
                $rootScope.SMSharingImage = sensorData['SMSharingImage'];
                $rootScope.eligibleForNewUserID = true;
                $rootScope.automaticEmotingEnabled = false;
                if (sensorData['automaticEmotingEnabled']) {
                    $rootScope.automaticEmotingEnabled = true;
                }
                $rootScope.showNumbering = sensorData['showNumbering'];
                $rootScope.automaticMoveToNextPage = sensorData['automaticMoveToNextPage'];

                $rootScope.percepticDrillDowns = sensorData['percepticDrillDowns'];

                $rootScope.combineSubmitEnabled = sensorData['combineSubmit'];
                $rootScope.emoticonPlacement = sensorData['emoticonPlacement'];
                if ($rootScope.emoticonPlacement === 'multiple') {
                    $rootScope.emotesPerRow = sensorData['emotesPerRow'];
                }
                $rootScope.drillDownPagingEnabled = sensorData['drillDownPaging'];
                $rootScope.drillDownPagingOptions = sensorData['drillDownPagingOptions'];
                if($rootScope.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.showPulseMeterChart)){
                    $rootScope.drillDownPagingOptions.showPulseMeterChart = false;
                }
                if($rootScope.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.showGeoMapChart)){
                    $rootScope.drillDownPagingOptions.showGeoMapChart = false;
                }
                if($rootScope.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.showSocialMediaBuzz)){
                    $rootScope.drillDownPagingOptions.showSocialMediaBuzz = false;
                }
                if($rootScope.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.thankYouPageHTML)){
                    $rootScope.drillDownPagingOptions.thankYouPageHTML = false;
                }
                if($rootScope.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.isLogicBasedTextEnabled)){
                    $rootScope.drillDownPagingOptions.isLogicBasedTextEnabled = false;
                }

                $rootScope.drillDownGroupings = sensorData['groups'];
                $rootScope.showEmojis = sensorData['showEmojis'];
                //accordions
                $rootScope.isCommentEnabled = sensorData['commentEnabled'];
                $rootScope.commentTheme = sensorData['commentTheme'];
                //resources
                $rootScope.resources = sensorData['resources'];
                if ($rootScope.resources) {
                    setResources();
                }
                //charts
                $rootScope.publishedCharts = sensorData['publishedCharts'];
                $rootScope.publishAnalytics = sensorData['publishAnalytics'];
                $rootScope.geoMapEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('PHM') !== -1 || ($rootScope.drillDownPagingOptions && $rootScope.drillDownPagingOptions.showGeoMapChart);
                $rootScope.generalEmotePulseEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('EPM') !== -1;
                $rootScope.generalGeoMapEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('PHM') !== -1;
                $rootScope.emotePulseEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('EPM') !== -1 || ($rootScope.drillDownPagingOptions && $rootScope.drillDownPagingOptions.showPulseMeterChart);
                $rootScope.emoteTimeLineEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('PTL') !== -1;
                $rootScope.commentBuzzEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('CB') !== -1

                $rootScope.statusBuzzEnabled = $rootScope.publishedCharts && $rootScope.publishedCharts.types.indexOf('StatusBuzz') !== -1

                //engagement
                $rootScope.engagementEnabled = sensorData['engagement'] && sensorData['engagement']['enabled'];

                //mobile push
                $rootScope.mobilePushEnabled = sensorData['mobileAutoRefreshEnabled'];

                //chart Thankyou
                if($rootScope.drillDownPagingOptions && ($rootScope.drillDownPagingOptions.showPulseMeterChart || $rootScope.drillDownPagingOptions.showGeoMapChart)){
                    if($rootScope.publishedCharts) {
                        if ($rootScope.publishedCharts.types.indexOf('EPM') == -1 && $rootScope.drillDownPagingOptions.showPulseMeterChart) {
                                $rootScope.publishedCharts.options['EPM'] = {typeChart: true, typeCount: false};
                                $rootScope.publishedCharts.types.push('EPM')
                        }
                        if ($rootScope.publishedCharts.types.indexOf('PHM') == -1 && $rootScope.drillDownPagingOptions.showGeoMapChart) {
                                $rootScope.publishedCharts.options['PHM'] = {
                                    focusCriteria: "emoterLoc",
                                    mapType: "emoteAverage",
                                    zoomLevel: 3
                                };
                                $rootScope.publishedCharts.types.push('PHM')
                        }
                    } else {
                        $rootScope.publishedCharts = {
                            options:{},
                            types: []
                        }

                        if($rootScope.drillDownPagingOptions.showPulseMeterChart){
                            $rootScope.publishedCharts.options['EPM'] = {typeChart: true, typeCount: false};
                            $rootScope.publishedCharts.types.push('EPM');
                        }
                        if($rootScope.drillDownPagingOptions.showGeoMapChart){
                            $rootScope.publishedCharts.options['PHM'] = {focusCriteria: "emoterLoc",mapType: "emoteAverage",zoomLevel: 3};
                            $rootScope.publishedCharts.types.push('PHM');
                        }
                    }
                }



                // if ($rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0 && $rootScope.doKIOSK && $rootScope.captureLoyaltyFirst) {
                //     $rootScope.isEligibleToProceed = false;
                // } else {
                    $rootScope.isEligibleToProceed = true;
                // }
                $rootScope.loyalty = {};
                if(angular.isUndefined(sensorData['loyalty'])){
                    $rootScope.loyalty.responseBasedLoyaltyEnabled = false;
                    $rootScope.loyalty.loyaltyPageLogics = [];
                } else{
                    $rootScope.loyalty.responseBasedLoyaltyEnabled = sensorData['loyalty']['responseBasedLoyaltyEnabled'];
                    $rootScope.loyalty.loyaltyPageLogics = sensorData['loyalty']['loyaltyPageLogics'];
                }

                $rootScope.emoSignatureDataPriority = "User";
                if(sensorData['emoSignatureDataPriority']){
                    $rootScope.emoSignatureDataPriority=sensorData['emoSignatureDataPriority'];
                }

                if(sensorData['newProfileCreationWarning']){
                    $rootScope.newProfileCreationWarning=sensorData['newProfileCreationWarning'];
                }

                if(sensorData['newProfileCreationReload']){
                    $rootScope.newProfileCreationReload=sensorData['newProfileCreationReload'];
                }
                if(sensorData['newProfileCreationReject']){
                    $rootScope.newProfileCreationReject = sensorData['newProfileCreationReject'];
                }
                if(sensorData['lastResponsePopulate'] && $rootScope.captureLoyaltyFirst){
                    $rootScope.lastResponsePopulate = sensorData['lastResponsePopulate'];
                }

                if(sensorData['stopRemapUserIDOnKIOSK']){
                    $rootScope.stopRemapUserIDOnKIOSK = sensorData['stopRemapUserIDOnKIOSK'];
                }else{
                    $rootScope.stopRemapUserIDOnKIOSK=false;
                }

                if(sensorData['profileDataFieldConversion']){
                    $rootScope.profileDataFieldConversion = sensorData['profileDataFieldConversion'];
                }

                if(sensorData['profileDataBasedScoreCalculation']){
                    $rootScope.profileDataBasedScoreCalculation = sensorData['profileDataBasedScoreCalculation'];
                }

                if(sensorData['responseLimitingTime']){
                    $rootScope.responseLimitingTime = sensorData['responseLimitingTime'];
                }
                if(sensorData['profileDateBasedLogic']){
                    $rootScope.profileDateBasedLogic = sensorData['profileDateBasedLogic'];
                }
                if(sensorData['localisationLabels']){
                    $rootScope.localisationLabels = sensorData['localisationLabels'];
                }
                //demographics
                $rootScope.demographicsEnabled = sensorData['captureDemographics'];
                $rootScope.demographicCaptureSensor = sensorData['demographicCaptureSensor'];
                $rootScope.preselectDemographics = sensorData['preselectDemographics'];

                $rootScope.isErrorOccure = false;
                $rootScope.errorDialogStyle = {'display': 'none'};
                $rootScope.actionProgressBarStyle = {'display': 'none'};

                //emoSignature Hierarchy
                $rootScope.enterpriseHierarchyCapture = sensorData['enterpriseHierarchyCapture'];
                $rootScope.emoSignatureModelId = sensorData['enterpriseHierarchyModelId'];

                if ($rootScope.enterpriseHierarchyCapture && $rootScope.emoSignatureModelId) {
                    //if sensor data is loaded through encodedSensorData
                    //if not, $rootScope.emoSignatureHierarchy is already set through the API call
                    if ($window.emoSignatureModel){
                        $rootScope.emoSignatureHierarchy = $window.emoSignatureModel.model;
                    }
                }

                //user name
                $rootScope.userVisibleName = userVisibleName;

                //emotags
                $rootScope.isEmoTagsEnabled = sensorData['displayTags'];
                if ($rootScope.isEmoTagsEnabled) {
                    $rootScope.emoTagsDescription = sensorData['emoTagsDescription'];
                    $rootScope.emoTags = sensorData['emotags'];
                }
                $rootScope.isStateBanner = false;
                //client side token validation
                $rootScope.clientBasedTokenValidation = sensorData['tokenBasedValidation'] ? sensorData['clientBasedTokenValidation'] : null;
                $rootScope.showTokenCapture = $rootScope.clientBasedTokenValidation ? $rootScope.clientBasedTokenValidation['validatedAt'] == 'start' && !($rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0 && $rootScope.doKIOSK) : false;

                //emoter capture broadcast
                if ($rootScope.isCapturePicture) {
                    $rootScope.$broadcast('START_WEBCAM');
                }
                if ($rootScope.responderImageCapture) {
                    $rootScope.$broadcast('START_WEBCAM');
                }

                $rootScope.isGDPRPopupEnabled = false;
                if(angular.isDefined(sensorData['isGDPRPopupEnabled'])){
                    $rootScope.isGDPRPopupEnabled = sensorData['isGDPRPopupEnabled'];
                }

                $rootScope.evaluationCampaign = false;
                if(angular.isDefined(sensorData['evaluationCampaign'])){
                    $rootScope.evaluationCampaign = sensorData['evaluationCampaign'];
                }

                $rootScope.scoreSegments;
                if(angular.isDefined(sensorData['scoreSegments'])){
                    $rootScope.scoreSegments = sensorData['scoreSegments'];
                }



                $rootScope.demographicPrePopulate = false;
                if(angular.isDefined(sensorData['demographicPrePopulate']) && sensorData['demographicPrePopulate']){
                    $rootScope.demographicPrePopulate = sensorData['demographicPrePopulate'];
                }
                $rootScope.ehGenerationRules = null;
                if(angular.isDefined(sensorData['ehGenerationRules']) && sensorData['ehGenerationRules']){
                    $rootScope.ehGenerationRules = sensorData['ehGenerationRules'];
                }

                //Social Media Channels
                if ($rootScope.isSMSharing) {
                    $rootScope.ShareableSMChannels = sensorData['ShareableSMChannels'];
                }

                if ($rootScope.isSMSharing && $rootScope.ShareableSMChannels) {
                    setSMChannels();
                }

                $rootScope.qrForProfileDataCapture = null;
                if(angular.isDefined(sensorData['qrForProfileDataCapture']) && sensorData['qrForProfileDataCapture']){
                    $rootScope.qrForProfileDataCapture = sensorData['qrForProfileDataCapture'];
                }

                $rootScope.responderImageCaptureOptions = null;
                if(angular.isDefined(sensorData['responderImageCaptureOptions']) && sensorData['responderImageCaptureOptions']){
                    $rootScope.responderImageCaptureOptions = sensorData['responderImageCaptureOptions'];
                }

                $rootScope.sensorCountdown = sensorData['sensorCountdown'];
                $rootScope.sensorCountdownOptions = null;
                if(angular.isDefined(sensorData['sensorCountdownOptions']) && sensorData['sensorCountdownOptions']){
                    $rootScope.sensorCountdownOptions = sensorData['sensorCountdownOptions'];
                }

                $rootScope.loyaltyEmoSignatureOverride = null;
                if(angular.isDefined(sensorData['loyaltyEmoSignatureOverride']) && sensorData['loyaltyEmoSignatureOverride']){
                    $rootScope.loyaltyEmoSignatureOverride = sensorData['loyaltyEmoSignatureOverride'];
                }

                $rootScope.responseFreeze = null;
                if(angular.isDefined(sensorData['responseFreeze']) && sensorData['responseFreeze']){
                    $rootScope.responseFreeze = sensorData['responseFreeze'];
                }

                $rootScope.intermediateSubmit = false;
                if(angular.isDefined(sensorData['intermediateSubmit']) && sensorData['intermediateSubmit']){
                    $rootScope.intermediateSubmit = sensorData['intermediateSubmit'];
                }

                $rootScope.personalizeEmoteThemePriority = null;
                if(angular.isDefined(sensorData['personalizeEmoteThemePriority']) && sensorData['personalizeEmoteThemePriority']){
                    $rootScope.personalizeEmoteThemePriority = sensorData['personalizeEmoteThemePriority'];
                }

                // Loyalty Logout Button
                $rootScope.isLogoutButtonShow = false;

                // default resource name set

                $rootScope.customeAccordionGeoMap = "GEO MAP";
                $rootScope.customeAccordionTimeLine = "EMOTE TIME SERIES";
                $rootScope.customeAccordionPulseMeter = "EMOTE PULSE";
                $rootScope.customeAccordionFeedback = "TEXT FEEDBACK";
                $rootScope.customeAccordionTwitterBuzz = "TWITTER BUZZ";
                $rootScope.customeAccordionFacebookBuzz = "FACEBOOK BUZZ";
                $rootScope.customeAccordionInstagramBuzz = "INSTAGRAM BUZZ";

                $rootScope.customeAccordionStatusBuzzEnabled = "STATUS BUZZ";

                //customer accordion
                if (!angular.isUndefined(sensorData['customAccordionHeaders'])) {
                    if (sensorData['customAccordionHeaders'].youTubeBuzz != "") {
                        $rootScope.customeAccordionYoutubeBuzz = sensorData['customAccordionHeaders'].youTubeBuzz;
                    } else {
                        $rootScope.customeAccordionYoutubeBuzz = sensorData['customAccordionHeaders'].youTubeBuzz;
                    }

                    if (sensorData['customAccordionHeaders'].uStreamBuzz != "") {
                        $rootScope.customeAccordionUStreamBuzz = sensorData['customAccordionHeaders'].uStreamBuzz;
                    } else {
                        $rootScope.customeAccordionUStreamBuzz = sensorData['customAccordionHeaders'].uStreamBuzz;
                    }

                    if (sensorData['customAccordionHeaders'].geoMap != "") {
                        $rootScope.customeAccordionGeoMap = sensorData['customAccordionHeaders'].geoMap;
                    } else {
                        $rootScope.customeAccordionGeoMap = "GEO MAP";
                    }

                    if (sensorData['customAccordionHeaders'].timeLine != "") {
                        $rootScope.customeAccordionTimeLine = sensorData['customAccordionHeaders'].timeLine;
                    } else {
                        $rootScope.customeAccordionTimeLine = "EMOTE TIME SERIES";
                    }

                    if (sensorData['customAccordionHeaders'].pulseMeter != "") {
                        $rootScope.customeAccordionPulseMeter = sensorData['customAccordionHeaders'].pulseMeter;
                    } else {
                        $rootScope.customeAccordionPulseMeter = "EMOTE PULSE";
                    }

                    if (sensorData['customAccordionHeaders'].feedback != "") {
                        $rootScope.customeAccordionFeedback = sensorData['customAccordionHeaders'].feedback;
                    } else {
                        $rootScope.customeAccordionFeedback = "TEXT FEEDBACK";
                    }

                    if (sensorData['customAccordionHeaders'].share != "") {
                        $rootScope.customeAccordionShare = sensorData['customAccordionHeaders'].share;
                    } else {
                        $rootScope.customeAccordionShare = sensorData['customAccordionHeaders'].share;
                    }

                    if (sensorData['customAccordionHeaders'].commentBuzz != "") {
                        $rootScope.customeAccordionCommentBuzz = sensorData['customAccordionHeaders'].commentBuzz;
                    } else {
                        $rootScope.customeAccordionCommentBuzz = "COMMENT BUZZ";
                    }

                    if (sensorData['customAccordionHeaders'].twitterBuzz != "") {
                        $rootScope.customeAccordionTwitterBuzz = sensorData['customAccordionHeaders'].twitterBuzz;
                    } else {
                        $rootScope.customeAccordionTwitterBuzz = "TWITTER BUZZ";
                    }

                    if (sensorData['customAccordionHeaders'].facebookBuzz != "") {
                        $rootScope.customeAccordionFacebookBuzz = sensorData['customAccordionHeaders'].facebookBuzz;
                    } else {
                        $rootScope.customeAccordionFacebookBuzz = "FACEBOOK BUZZ";
                    }

                    if (sensorData['customAccordionHeaders'].instagramBuzz != "") {
                        $rootScope.customeAccordionInstagramBuzz = sensorData['customAccordionHeaders'].instagramBuzz;
                    } else {
                        $rootScope.customeAccordionInstagramBuzz = "INSTAGRAM BUZZ";
                    }

                    if (sensorData['customAccordionHeaders'].imageBuzz != "") {
                        $rootScope.customeAccordionImageBuzz = sensorData['customAccordionHeaders'].imageBuzz;
                    } else {
                        $rootScope.customeAccordionImageBuzz = sensorData['customAccordionHeaders'].imageBuzz;
                    }

                    if (sensorData['customAccordionHeaders'].statusBuzz != "") {
                        $rootScope.customeAccordionStatusBuzzEnabled = sensorData['customAccordionHeaders'].statusBuzz;
                    } else {
                        $rootScope.customeAccordionStatusBuzzEnabled = "STATUS BUZZ";
                    }
                }

                if(sensorData['groups']){
                    $rootScope.mediaFiles = {};
                    angular.forEach(sensorData['groups'],function (group,index) {
                        if(group.mediaFiles){
                            $rootScope.mediaFiles[group.id] = group.mediaFiles
                            $rootScope.mediaFiles[group.id]['groupId'] = group.id
                            group.mediaFiles.files = [];
                        }
                    })
                }

                $rootScope.telephoneAllowedCountries = [];
                if(sensorData['telephoneAllowedCountries']){
                    $rootScope.telephoneAllowedCountries = sensorData['telephoneAllowedCountries'];
                }


                createPerceptionDrillDownMap($rootScope.percepticDrillDowns);
                //Check whether url refersh through PhantomJS Library
                var field = "phantom";

                $rootScope.proceedToUserInteraction = true;

                utilityProviderService.findQueryParam(url, field, function (fieldExsists) {
                    if (fieldExsists == true) {
                        $rootScope.proceedToUserInteraction = false;
                    } else if (fieldExsists == false) {
                        $rootScope.proceedToUserInteraction = true;
                    }
                });
            }
            function processSensorTitleWithEmoSignatures(title){
                var placeholders = title.match(/\{(.*?)\}/g) || [];
                angular.forEach(placeholders, function(placeholder, i){
                    var placeholderText = placeholder.substring(1, placeholder.length -1).trim();
                    if($window.emoSignature && $window.emoSignature[placeholderText]){
                        var peerReview = $window.emoSignature.peerReview == 'true';
                        var emoSigValues = Object.keys($window.emoSignature).map(function(key) { return $window.emoSignature[key]; });
                        if(peerReview && emoSigValues.indexOf('Self') > -1 && placeholderText == "Evaluator Relationship"){
                            title = title.replace(placeholder, "yourself");
                        }else{
                            title = title.replace(placeholder, $window.emoSignature[placeholderText]);
                        }
                    }else{
                        title = title.replace(placeholder, "N/A");
                    }
                })
                return title;
            }
            function getSensorWithRandomizedQuestions(groups,localization){
                var sensorHasRandomizedPages = false;
                $rootScope.originalDrillDownGroupings = angular.copy(groups);
                var draftSurvey;
                if($rootScope.draftResponseObj){
                    try{
                        if(typeof $rootScope.draftResponseObj == "string"){
                            draftSurvey = JSON.parse($rootScope.draftResponseObj);
                        }else{
                            draftSurvey = $rootScope.draftResponseObj;
                        }
                    }catch(e){}
                }

                if($rootScope.draftResponse && draftSurvey && draftSurvey['randomizedGroups']){
                    var randomizedGroups = draftSurvey['randomizedGroups'];
                    var randomizedGroupsMap = {};
                    angular.forEach(randomizedGroups, function(group, i) {
                        randomizedGroupsMap[group.id] = group;
                    });

                    angular.forEach(groups, function(group, i) {
                        if(group.randomization){
                            sensorHasRandomizedPages = true;
                            var randomPickedDrillDowns = [];
                            var randomQuestionCount = group.randomQuestionCount;
                            var drillDownCount = group.drilldowns.length;

                            if(randomizedGroupsMap.hasOwnProperty(group.id)){

                                //check cache drilldown is valid
                                angular.forEach(randomizedGroupsMap[group.id].drilldowns,function (value) {
                                    if(group.drilldowns.indexOf(value) != -1){
                                        randomPickedDrillDowns.push(value);
                                    }
                                })

                                var uniqueDrilldowns = [];
                                angular.forEach(group.drilldowns,function (value) {
                                    if(randomPickedDrillDowns.indexOf(value) == -1){
                                        uniqueDrilldowns.push(value);
                                    }
                                })

                                drillDownCount = uniqueDrilldowns.length;
                                if(randomPickedDrillDowns.length != randomQuestionCount){
                                    for(var i=0; i<randomQuestionCount - randomPickedDrillDowns.length; i++){
                                        var randomIndex = Math.floor(Math.random() * [drillDownCount]);
                                        randomPickedDrillDowns.push(uniqueDrilldowns[randomIndex]);
                                        drillDownCount--;
                                        uniqueDrilldowns.splice(randomIndex, 1);
                                    }
                                }
                            } else {
                                for(var i=0; i<randomQuestionCount; i++){
                                    var randomIndex = Math.floor(Math.random() * [drillDownCount]);
                                    randomPickedDrillDowns.push(group.drilldowns[randomIndex]);
                                    drillDownCount--;
                                    group.drilldowns.splice(randomIndex, 1);
                                }
                            }
                            group.drilldowns = randomPickedDrillDowns;
                        }
                    });

                } else{
                    angular.forEach(groups, function(group, i) {
                        if(group.randomization){
                            sensorHasRandomizedPages = true;
                            if(localization){
                                for(var c = 0; c < $rootScope.drillDownGroupings.length ; c++){
                                    if($rootScope.drillDownGroupings[c].id == group.id){
                                        group.drilldowns = $rootScope.drillDownGroupings[c].drilldowns;
                                        break;
                                    }
                                }
                            } else{
                                var randomPickedDrillDowns = [];
                                var randomQuestionCount = group.randomQuestionCount;
                                var drillDownCount = group.drilldowns.length;
                                for(var i=0; i<randomQuestionCount; i++){
                                    var randomIndex = Math.floor(Math.random() * [drillDownCount]);
                                    randomPickedDrillDowns.push(group.drilldowns[randomIndex]);
                                    drillDownCount--;
                                    group.drilldowns.splice(randomIndex, 1);
                                }
                                group.drilldowns = randomPickedDrillDowns;
                            }
                        }
                    });
                }
                return [sensorHasRandomizedPages, groups];
            }

            function showHideSensorPagesBasedOnEhFields(sensor, emoSignature, showHideRules, matchingRule){
                if(!emoSignature || (!showHideRules && !matchingRule)){
                    return false;
                }
                if(showHideRules && !matchingRule){
                    var ruleHeaders = showHideRules.headers || [];
                    var rules = showHideRules.rules || {};
                    var ruleKey = "";
                    angular.forEach(ruleHeaders, function(header, i){
                        if(emoSignature[header]){
                            ruleKey += header + "~~" +emoSignature[header] + "__"
                        }
                    });
                    if(!ruleKey){
                        return false;
                    }
                    ruleKey = ruleKey.substring(0, ruleKey.length - 2);
                    matchingRule = rules[ruleKey];
                    if(!matchingRule || (!matchingRule.questions && !matchingRule.pages) || !matchingRule.action){
                        return false;
                    }
                }
                var ruleQuestions = matchingRule.questions || [];
                var rulePages = matchingRule.pages || [];
                if(matchingRule.type == "multiNodeEH"){
                    var ruleMatchingQuestions = [];
                    angular.forEach(ruleQuestions, function(ruleQuestion, i){
                        var matchingEHValue = emoSignature[ruleQuestion.ehKey];
                        if(matchingEHValue && ruleQuestion.ehValues && ruleQuestion.ehValues.indexOf(matchingEHValue) > -1 && ruleMatchingQuestions.indexOf(ruleQuestion.question) < 0){
                            ruleMatchingQuestions.push(ruleQuestion.question);
                        }
                    });
                    ruleQuestions = ruleMatchingQuestions;
                    var ruleMatchingPages = [];
                    angular.forEach(rulePages, function(rulePage, i){
                        var matchingEHValueForPage = emoSignature[rulePage.ehKey];
                        if(matchingEHValueForPage && rulePage.ehValues && rulePage.ehValues.indexOf(matchingEHValueForPage) > -1 && ruleMatchingPages.indexOf(rulePage.page) < 0){
                            ruleMatchingPages.push(rulePage.page);
                        }
                    });
                    rulePages = ruleMatchingPages;
                }
                var questionPageIndexMap = {};
                var sensorLogo = matchingRule.sensorLogo;
                if(sensorLogo){
                    sensorData.sensorLogo = sensorData.sensorLogo || {};
                    sensorData.sensorLogo.sensorLogoName = sensorLogo;
                }
                var background = matchingRule.background;
                if(background){
                    $('#master-section').css('background-image', 'url(' + background + ')');
                }
                if(sensor.groups && Array.isArray(sensor.groups)){
                    if(rulePages.length > 0){
                        for(var i =sensor.groups.length -1; i >= 0; i--){
                            if ((matchingRule.action == "hide" && rulePages.indexOf(sensor.groups[i].id) > -1) ||
                                (matchingRule.action == "show" && rulePages.indexOf(sensor.groups[i].id) < 0)
                            ) {
                                sensor.groups.splice(i, 1);
                            }
                        }
                    }
                    for(var i =0; i<sensor.groups.length; i++){
                        var pageDrillDowns = sensor.groups[i].drilldowns || [];
                        for (var j = 0; j < pageDrillDowns.length; j++) {
                            questionPageIndexMap[pageDrillDowns[j]] = i;
                        }
                    }
                }
                for(var k = sensor.percepticDrillDowns.length - 1; k >= 0; k--) {
                    if(ruleQuestions.length > 0){
                        if ((matchingRule.action == "hide" && ruleQuestions.indexOf(sensor.percepticDrillDowns[k].id) > -1) ||
                            (matchingRule.action == "show" && ruleQuestions.indexOf(sensor.percepticDrillDowns[k].id)  < 0)
                        ) {
                            var pageIndex = questionPageIndexMap[sensor.percepticDrillDowns[k].id];
                            if (typeof pageIndex !== "undefined") {
                                var questionIndex = sensor.groups[pageIndex].drilldowns.indexOf(sensor.percepticDrillDowns[k].id);
                                sensor.groups[pageIndex].drilldowns.splice(questionIndex, 1);
                                if (sensor.groups[pageIndex].drilldowns.length == 0 && !sensor.groups[pageIndex].commentTheme) {
                                    sensor.groups.splice(pageIndex, 1);
                                }
                            }
                        }
                    }
                }

            }

            function performActivityCall(tries) {
                var deviceID = utilityProviderService.tryJsonParse($cookies.get('deviceID'));

                var requestParams = {
                    deviceID: deviceID,
                    embedThemes: true
                };

                $http({
                    url: $window.urlV,
                    method: 'GET',
                    contentType: 'application/json',
                    params: requestParams,
                    headers: {'x-api-key': $rootScope.sensorAPIKey}
                }).then(function (data) {
                    console.log('success Object loading!', data.data);
                    sensorData = data.data.activities[0];
                    $rootScope.isErrorOccure = false;
                    //emoSignature Hierarchy loading
                    if (sensorData['enterpriseHierarchyCapture'] && sensorData['enterpriseHierarchyModelId']) {
                        $rootScope.emoSignatureModelId = sensorData['enterpriseHierarchyModelId'];
                        $rootScope.companyID = sensorData['companyID'];
                        enterpriseHierarchyDataService.getEmoSignatureModelDetails(0, function () {
                            removeEngagement();
                            setSensorData(sensorData, true);
                            $rootScope.$broadcast('Sensor Data Loaded');
                            showContent();
                        });
                    }
                }, function (status) {
                    console.log('Error state code' + status.status);
                    $rootScope.isLoadingIndicatorShow = false;
                    $rootScope.sensorDataExist = false;

                    if (status.status == 400) {
                        alertGeneratorService.addAlert({
                            status: 'danger',
                            msg: status.data.details
                        });

                    }
                    else if (status.status == 500) {
                        if (status.data.code == 7018) {
                            $rootScope.errorDialogStyle = {'display': 'table'};
                            $rootScope.isErrorOccure = true;
                        }
                        alertGeneratorService.addAlert({
                            status: 'danger',
                            msg: status.data.details
                        });

                    }
                    else {
                        alertGeneratorService.addAlert({
                            status: 'danger',
                            msg: 'Problem occurred. Try reloading the sensor again.'
                        });
                    }
                });

            }

            var getKioskImgUrl = function () {
                if ($rootScope.doKIOSK && $rootScope.kioskImgShow) {
                    return $rootScope.kioskImageURL;
                }
            };

            function showContent() {

                //set attributes for logging
                systemLoggerService.setGlobalSensorAttributes();

                var imageSource = new Image();
                var imgURL = getKioskImgUrl();
                var updatedImgURL = null;
                if (imgURL && imgURL.indexOf('https') == -1) {
                    updatedImgURL = imgURL.replace('http', 'https');
                }
                else {
                    updatedImgURL = imgURL;
                }
                imageSource.src = updatedImgURL;

                if ($rootScope.doKIOSK && $rootScope.kioskImgShow && $rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0) {
                    $rootScope.isLoadingIndicatorShow = true;
                    imageSource.onload = function () {

                        $rootScope.isDemographicSensor = false;
                        // $rootScope.$on('loyalty data captured', function () {
                            document.getElementById('img').src = updatedImgURL;
                            $rootScope.isLoadingIndicatorShow = false;
                            $rootScope.showLoyaltyCapture = true;
                            $rootScope.sensorDataExist = true;
                        // });

                        setTimeout(function () {
                            $("#master-section").fadeIn("500", function () {
                            });
                            $("#mainContainer").fadeIn("500", function () {
                            });
                        }, 200);

                    }

                    imageSource.onerror = function () {
                        console.log("Cannot load image");
                        $rootScope.isLoadingIndicatorShow = true;
                    }
                }

                if ($rootScope.doKIOSK && $rootScope.kioskImgShow && !($rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0)) {
                    document.getElementById('image').src = imageSource.src;
                    imageSource.onload = function () {
                        console.log("Image loaded !");
                        $rootScope.sensorDataExist = true;
                        $rootScope.isDemographicSensor = false;

                        if ($rootScope.sensorDataExist) {
                            $rootScope.isLoadingIndicatorShow = false;
                        }
                        setTimeout(function () {
                            $("#master-section").fadeIn("500", function () {
                            });
                            $("#mainContainer").fadeIn("500", function () {
                            });
                        }, 200);

                    }
                    imageSource.onerror = function () {
                        console.log("Cannot load image");
                        $rootScope.imgLoaded = false;
                    }
                }

                if ($rootScope.doKIOSK && $rootScope.kioskImgShow && !$rootScope.captureLoyaltyFirst && $rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0) {
                    document.getElementById('image').src = imageSource.src;
                    imageSource.onload = function () {
                        console.log("Image loaded !");
                        $rootScope.sensorDataExist = true;
                        $rootScope.isDemographicSensor = false;

                        if ($rootScope.sensorDataExist) {
                            $rootScope.isLoadingIndicatorShow = false;
                        }
                        setTimeout(function () {
                            $("#master-section").fadeIn("500", function () {
                            });
                            $("#mainContainer").fadeIn("500", function () {
                            });
                        }, 200);

                    }
                    imageSource.onerror = function () {
                        console.log("Cannot load image");
                        $rootScope.imgLoaded = false;
                    }
                }

                if (!$rootScope.kioskImgShow) {
                    $rootScope.sensorDataExist = true;
                    $rootScope.isDemographicSensor = false;

                    if ($rootScope.sensorDataExist) {
                        $rootScope.isLoadingIndicatorShow = false;
                    }
                    setTimeout(function () {
                        $("#master-section").fadeIn("500", function () {
                        });
                        $("#mainContainer").fadeIn("500", function () {
                        });
                    }, 200);
                }

                if(sensorData['emoteRestricted']){

                    $rootScope.disableEmoteSubmit = true;
                    alertGeneratorService.addAlert({
                        type: 'danger',
                        msg: "This survey isn't accepting new responses",
                        timeOut:100000
                    });

                    window.emoteRestricted = true;
                    return;

                }
            }

            var load = function () {
                if (sensorData) {
                    return sensorData;
                }
            };

            var getActivityProperty = function (key) {
                if (sensorData) {
                    return sensorData[key];
                }
            };

            var sendSensorInteraction = function (visitedInteraction, videoInteraction, emoterMetaDataObject, emoterLocationInfo, tries) {

                if(isSensorPreviewG){
                    return;
                }

                var captureTrace = getActivityProperty("captureTrace");

                if ($rootScope.proceedToUserInteraction) {
                    if (captureTrace != "undefined" && (captureTrace == true || captureTrace == "true")) {
                        var deviceID = utilityProviderService.tryJsonParse($cookies.get('deviceID'));
                        var companyID = getActivityProperty('companyID');
                        var activityID = getActivityProperty('_id');
                        var emotag;

                        if (typeof visitedInteraction != "undefined" && visitedInteraction != null) {
                            emotag = $rootScope.userID + "_" + visitedInteraction;
                        } else if (typeof videoInteraction != "undefined" && videoInteraction != null) {
                            emotag = $rootScope.userID + "_" + videoInteraction;
                        }

                        var postData;

                        if (visitedInteraction) {
                            postData = {
                                deviceID: deviceID,
                                companyID: companyID,
                                userID: $rootScope.userID,
                                activityID: activityID,
                                emoTag: emotag,
                                visitedInteraction: visitedInteraction
                            };
                        } else if (videoInteraction) {
                            postData = {
                                deviceID: deviceID,
                                companyID: companyID,
                                userID: $rootScope.userID,
                                activityID: activityID,
                                emoTag: emotag,
                                videoInteraction: videoInteraction
                            };
                        }

                        if ($rootScope.loyaltyRegistered) {
                            postData['loyaltyRegistered'] = $rootScope.loyaltyRegistered;
                        }

                        if (emoterMetaDataObject) {
                            postData['emoterMetaData'] = {data: emoterMetaDataObject};
                        } else {
                            postData['emoterMetaData'] = {};
                        }

                        if (emoterLocationInfo) {
                            postData['metadata'] = {location: emoterLocationInfo};
                        } else {
                            postData['metadata'] = {};
                        }

                        if (emoSignature) {
                            postData['metadata']['emoSignature'] = emoSignature;
                        }

                        if (window.embeddedEmailID) {
                            postData['metadata']['embeddedEmailID'] = window.embeddedEmailID;
                        }

                        if (window.embeddedSMSID) {
                            postData['metadata']['embeddedSMSID'] = window.embeddedSMSID;
                        }
                        $http({
                            method: 'POST',
                            url: 'https://sensorapi.emojot.com/v1/userInteraction',
                            data: JSON.stringify({interactions: [postData]}),
                            contentType: 'application/json',
                            headers: {'x-api-key': $rootScope.sensorAPIKey}
                        }).then(function (status) {
                            //console.log(status);
                            if (status.data && status.data.viewID) {
                                $rootScope.currentViewID = status.data.viewID;
                            }
                            $rootScope.$broadcast('Interaction Data Sent');
                        }, function (status) {
                            alertGeneratorService.addAlert({
                                type: 'danger',
                                msg: 'Your login session has expired. Please login again!'
                            });
                        });
                    }
                }
            };

            reverseGeoCodeService.getLocation(function (emoterLocationInfo) {
                var tries = 0;
                window.locationInfo = emoterLocationInfo;
                $rootScope.$on('Send interaction event', sendSensorInteraction.bind(this, visitedInteraction, null, window.detector, emoterLocationInfo, tries));
                if ($rootScope.loyaltyInitialized) {
                    $rootScope.$broadcast('Send interaction event');
                }
                if ("production" === window.elkEnvironment || "staging" === window.elkEnvironment) {
                    insertGlobalDataToLogListener({
                        identifiers: window.detector,
                        locationInfo: window.locationInfo,
                        domain: "Mobile",
                        environment: elkEnvironment,
                        actionType: "navigation",
                        status: 'success',
                        date: (new Date()).toISOString(),
                        url: window.location.href
                    });
                }
            });

            function createPerceptionDrillDownMap(percepticDrillDowns) {
                try {
                    $rootScope.percepticDrillDownsMap = {};
                    $rootScope.loyaltySyncPercepticDrilldownsMap = {};
                    for (var x = 0; x < percepticDrillDowns.length; x++) {
                        $rootScope.percepticDrillDownsMap[percepticDrillDowns[x].id] = percepticDrillDowns[x];
                        if(percepticDrillDowns[x].loyaltySyncEnabled){
                            $rootScope.loyaltySyncPercepticDrilldownsMap[percepticDrillDowns[x].id] = percepticDrillDowns[x];
                        }
                    }
                }catch (e) {
                    console.log("error while creating map")
                }
            }

            getSensorData();


            function setResources() {
                var resourceLength = $rootScope.resources.length;
                for (var count = 0; count < resourceLength; count++) {

                    if ($rootScope.resources[count].type === 'fb') {
                        $rootScope.isFacebookEnabled = true;
                    }
                    else if ($rootScope.resources[count].type === 'twitter') {
                        $rootScope.isTwitterEnabled = true;
                    }
                    else if ($rootScope.resources[count].type === 'instagram') {
                        $rootScope.isInstagramEnabled = true;
                    }
                    else if ($rootScope.resources[count].type === 'youtube') {
                        $rootScope.isYouTubeEnabled = true;
                    }
                    else if ($rootScope.resources[count].type === 'ustream') {
                        $rootScope.isUStreamEnabled = true;
                    }
                    else if ($rootScope.resources[count].type === 'image') {
                        $rootScope.isImageEnabled = true;
                    }
                }
            }

            function removeEngagement() {
                $rootScope.previousActivityRedirects = $cookies.get("previousEngagementRedirects");

                var found = false;

                if (typeof $rootScope.previousActivityRedirects != "undefined" && $rootScope.previousActivityRedirects != null) {
                    $rootScope.parsedPreviousActivityRedirects = JSON.parse($rootScope.previousActivityRedirects);

                    for (var i = 0; i < $rootScope.parsedPreviousActivityRedirects.length; i++) {
                        if ($rootScope.parsedPreviousActivityRedirects[i].activityID == $rootScope.activityID) {
                            found = true;
                            $rootScope.parsedPreviousActivityRedirects.splice(i, 1);
                            break;
                        }
                    }

                    if (found) {
                        $cookies.putObject('previousEngagementRedirects', utilityProviderService.tryJsonParse($rootScope.parsedPreviousActivityRedirects),{secure:true,samesite:"none"});

                    }
                }
            }

            function setSMChannels() {
                if ($rootScope.isSMSharing) {
                    var length = $rootScope.ShareableSMChannels.length;

                    for (var count = 0; count < length; count++) {
                        if ($rootScope.ShareableSMChannels[count] === 'facebook') {
                            $rootScope.isFacebookSM = true;
                        } else if ($rootScope.ShareableSMChannels[count] === 'twitter') {
                            $rootScope.isTwitterSM = true;
                        } else if ($rootScope.ShareableSMChannels[count] === 'g+') {
                            $rootScope.isGoogleSM = true;
                        } else if ($rootScope.ShareableSMChannels[count] === 'whatsapp') {
                            $rootScope.isWhatsappSM = true;
                        } else if ($rootScope.ShareableSMChannels[count] === 'viber') {
                            $rootScope.isViberSM = true;
                        }
                    }
                }
            }

            $rootScope.idleTime = 0;
            $rootScope.timeoutWhenIdleSet = false;

            var timerIncrement = function () {
                $rootScope.idleTime += 1000;
                if ($rootScope.idleTime >= $rootScope.resetTimeoutForIdling) {
                    window.redirectPage(location.href);
                    clearInterval($rootScope.idleInterval);
                }
                if ($rootScope.idleTime + 30000 >= $rootScope.resetTimeoutForIdling) {
                    var countdown = Math.floor(($rootScope.resetTimeoutForIdling - $rootScope.idleTime) / 1000);

                    $rootScope.$apply(function () {
                        $rootScope.countdown = countdown > 0 ? countdown : 0;
                        $('#countdownModal').modal('show');
                    });
                }
            };

            var resetKioskIdleTime = function () {
                //if click,touch or keypress occur after showing the modal, close it
                if($('#countdownModal').hasClass('in')){
                    $('#countdownModal').modal('hide');
                }
                $rootScope.idleTime = 0;
                clearInterval($rootScope.idleInterval);
                $rootScope.idleInterval = setInterval(timerIncrement, 1000); //1s
            };

            var setKioskTimeout = function () {
                if(!$rootScope.timeoutWhenIdleSet) {
                    $rootScope.idleInterval = setInterval(timerIncrement, 1000); //1s
                    $rootScope.timeoutWhenIdleSet = true;
                }
                else{
                    resetKioskIdleTime();
                }
            };

            if($rootScope.resetTimeoutForIdling > 0 && $rootScope.doKIOSK ){
                document.addEventListener("click", function(event){
                    setKioskTimeout();
                });
                document.addEventListener("keydown", function(event){
                    setKioskTimeout();
                });
            }

            return {
                getSensorData: getSensorData,
                load: load,
                getSensorWithRandomizedQuestions : getSensorWithRandomizedQuestions,
                getActivityProperty: getActivityProperty,
                sendSensorInteraction: sendSensorInteraction,
                processSensorTitleWithEmoSignatures : processSensorTitleWithEmoSignatures,
                showHideSensorPagesBasedOnEhFields : showHideSensorPagesBasedOnEhFields,
                createPerceptionDrillDownMap : createPerceptionDrillDownMap,
                loadDraftResponse : loadDraftResponse,
                getSurveyDraftKeyObject : getSurveyDraftKeyObject,
                getSurveyDraftKey : getSurveyDraftKey
            }
        }]);

})();
