(function () {
    angular.module('mobileApp').service('conditionalLibraryLoaderService', ['$rootScope','$window', 'sensorDataLoaderService', function ($rootScope,$window, sensorDataLoaderService) {

        // var deregisterListener = $rootScope.$on('Sensor Data Loaded', function () {
        //     loadLibraries();
        // });

        $rootScope.instagramLoaded=false;
        $rootScope.socketIOLoaded=false;
        $rootScope.youtubeLoaded=false;
        $rootScope.highchartLoaded=false;
        $rootScope.qrScanLoaded=false;
        $rootScope.webCamJSLoaded=false;

        var loadLibraries = function(){
            if($rootScope.isInstagramEnabled) {
                var instagram_script_tag = document.createElement('script');
                instagram_script_tag.setAttribute("type", "text/javascript");
                instagram_script_tag.setAttribute("src", "https://platform.instagram.com/en_US/embeds.js");
                instagram_script_tag.setAttribute("defer", "");

                if (instagram_script_tag.readyState) {
                    instagram_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.instagramLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    instagram_script_tag.onload = function () {
                        $rootScope.instagramLoaded=true;
                        finalizeLibraryLoading();
                    };
                }

                (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(instagram_script_tag);
            }
            if($rootScope.engagementEnabled || $rootScope.mobilePushEnabled) {
                var socketio_script_tag = document.createElement('script');
                socketio_script_tag.setAttribute("type", "text/javascript");
                socketio_script_tag.setAttribute("crossorigin", "anonymous");
                socketio_script_tag.setAttribute("src", "https://emojot.com/emojot_mobile/libs/js/socket.io.min.js");

                if (socketio_script_tag.readyState) {
                    socketio_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.socketIOLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    socketio_script_tag.onload = function () {
                        $rootScope.socketIOLoaded=true;
                        finalizeLibraryLoading();
                    };
                }

                (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(socketio_script_tag);
            }
            if($rootScope.isYouTubeEnabled) {
                var youtube_script_tag = document.createElement('script');
                youtube_script_tag.setAttribute("type", "text/javascript");
                youtube_script_tag.setAttribute("src", "https://www.youtube.com/iframe_api");
                youtube_script_tag.setAttribute("defer", "");

                if (youtube_script_tag.readyState) {
                    youtube_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.youtubeLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    youtube_script_tag.onload = function () {
                        $rootScope.youtubeLoaded=true;
                        finalizeLibraryLoading();
                    };
                }

                (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(youtube_script_tag);
            }
            if($rootScope.emotePulseEnabled || $rootScope.isEmoteTimeSeriesEnabled) {
                var highchart_script_tag = document.createElement('script');
                highchart_script_tag.setAttribute("id", "scriptHighcharts");
                highchart_script_tag.setAttribute("type", "text/javascript");
                highchart_script_tag.setAttribute("src", "https://code.highcharts.com/highcharts.js");
                highchart_script_tag.setAttribute("defer", "");

                if (highchart_script_tag.readyState) {
                    highchart_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.highchartLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    highchart_script_tag.onload = function () {
                        $rootScope.highchartLoaded=true;
                        finalizeLibraryLoading();
                    };
                }
                if(!document.getElementById("scriptHighcharts")) {
                    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(highchart_script_tag);
                }
            }

            if($rootScope.qrForProfileDataCapture) {
                var qrcode_script_tag = document.createElement('script');
                qrcode_script_tag.setAttribute("id", "qrCodeLib");
                qrcode_script_tag.setAttribute("type", "text/javascript");
                qrcode_script_tag.setAttribute("src", "https://resources.emojot.com/emojot_mobile/libs/js/qrscan/qrcodelib.js");


                if (qrcode_script_tag.readyState) {
                    qrcode_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.qrScanLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    qrcode_script_tag.onload = function () {
                        $rootScope.qrScanLoaded=true;
                        finalizeLibraryLoading();
                    };
                }

                if(!document.getElementById("qrCodeLib")) {
                    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(qrcode_script_tag);
                }
                //document.getElementById("qrCodeLib").setAttribute("src","https://resources.emojot.com/emojot_mobile/libs/js/qrscan/qrcodelib.js");
                //$rootScope.qrScanLoaded=true;

                var webcodecam_script_tag = document.createElement('script');
                webcodecam_script_tag.setAttribute("id", "webcodecamLib");
                webcodecam_script_tag.setAttribute("type", "text/javascript");
                webcodecam_script_tag.setAttribute("src", "https://resources.emojot.com/emojot_mobile/libs/js/qrscan/webcodecamjs.js");

                if (webcodecam_script_tag.readyState) {
                    webcodecam_script_tag.onreadystatechange = function () { // For old versions of IE
                        if (this.readyState == 'complete' || this.readyState == 'loaded') {
                            $rootScope.webCamJSLoaded=true;
                            finalizeLibraryLoading();
                        }
                    };
                } else {
                    webcodecam_script_tag.onload = function () {
                        $rootScope.webCamJSLoaded=true;
                        finalizeLibraryLoading();
                    };
                }
                if(!document.getElementById("webcodecamLib")) {
                    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(webcodecam_script_tag);
                }

            }

           // deregisterListener();
            //braodcast after DOM, and all the images, scripts, links and sub-frames have finished loading.
            //window.onload = function (){
            //    $rootScope.$broadcast('Page Loading Complete');
            //};
        };

        var finalizeLibraryLoading =function(){
            var allLoaded=true;
            if($rootScope.isInstagramEnabled) {
                if(!$rootScope.instagramLoaded){
                    allLoaded=false
                }
            }
            if($rootScope.engagementEnabled || $rootScope.mobilePushEnabled) {
                if(!$rootScope.socketIOLoaded){
                    allLoaded=false
                }
            }
            if($rootScope.isYouTubeEnabled) {
                if(!$rootScope.youtubeLoaded){
                    allLoaded=false
                }
            }
            if($rootScope.emotePulseEnabled || $rootScope.isEmoteTimeSeriesEnabled) {
                if(!$rootScope.highchartLoaded){
                    allLoaded=false
                }
            }

            if($rootScope.qrForProfileDataCapture) {
                if(!$rootScope.qrScanLoaded){
                    allLoaded=false
                }
                if(!$rootScope.webCamJSLoaded){
                    allLoaded=false
                }
            }

            if(allLoaded){
                $rootScope.$broadcast('Page Loading Complete');
            }
        }

        if($window.encodedSensorData) {
            loadLibraries();
        }else{
             $rootScope.$on('Sensor Data Loaded', function () {
                 loadLibraries();
             });
        }

        return{
            loadLibraries : loadLibraries
        }

    }]);
})();