(function () {
    angular.module('mobileApp').service('socketIOConnectionService', ['$cookies', '$rootScope', 'sensorDataLoaderService', 'utilityProviderService', function ($cookies, $rootScope, sensorDataLoaderService, utilityProviderService) {

        var client_data = {
            activityID: $rootScope.activityID,
            companyID: $rootScope.companyID,
            deviceID: utilityProviderService.tryJsonParse($cookies.get('deviceID'))
        };

        var connectEngagementChannel = function (callback) {
            var engagementChannel = io.connect("https://emojot.com/engagementChannel", {
                'timeout': 2000,
                'reconnection': true,
                'reconnectionDelay': 1000,
                'reconnectionDelayMax': 5000,
                'reconnectionAttempts': 5
            });

            engagementChannel.once('connect', function () {
                console.log("EngagementChannel Connect...");
                engagementChannel.emit('register', {client_data: client_data});
            });

            engagementChannel.on('reconnect', function () {
                console.log("EngagementChannel Reconnect...");
                engagementChannel.emit('register', {client_data: client_data});
            });

            engagementChannel.on('reconnecting', function () {
                console.log("EngagementChannel Reconnecting...");
            });

            engagementChannel.on('error', function () {
                console.log("Error while connecting to connect to the Emojot Notification Service.");
            });

            engagementChannel.on('reconnect_error', function () {
                console.log("Error while reconnecting to connect to the Emojot Notification Service.");
            });

            engagementChannel.on('reconnect_failed', function () {
                console.log("Failed to connect to the Emojot Notification Service. Please reload the sensor!", "Error");
            });

            engagementChannel.on('disconnect', function () {
                console.log("EngagementUpdateChannel disconnected. Connecting Back...");
            });

            engagementChannel.on('Emojot Broadcast Event', function (msg) {
                callback(msg, true);
            });
        };

        var connectSensorUpdateChannel = function (callback) {

            var sensorUpdateChannel = io.connect("https://emojot.com/sensorUpdateChannel", {
                'timeout': 2000,
                'reconnection': true,
                'reconnectionDelay': 1000,
                'reconnectionDelayMax': 5000,
                'reconnectionAttempts': 5
            });

            sensorUpdateChannel.once('connect', function () {
                console.log("SensorUpdateChannel Connect...");
                sensorUpdateChannel.emit('register', {client_data: client_data});
            });

            sensorUpdateChannel.on('reconnecting', function () {
                console.log("SensorUpdateChannel Reconnect...");
            });

            sensorUpdateChannel.on('reconnect', function () {
                console.log("SensorUpdateChannel Reconnect...");
                sensorUpdateChannel.emit('register', {client_data: client_data});
            });

            sensorUpdateChannel.on('error', function () {
                console.log("Error while connecting to connect to the Emojot Notification Service.");
            });

            sensorUpdateChannel.on('reconnect_error', function () {
                console.log("Error while reconnecting to connect to the Emojot Notification Service.");
            });

            sensorUpdateChannel.on('reconnect_failed', function () {
                console.log("Failed to connect to the Emojot Notification Service. Please reload the sensor!", "Error");
            });

            sensorUpdateChannel.on('disconnect', function () {
                console.log("SensorUpdateChannel disconnected. Connecting Back...");
            });

            sensorUpdateChannel.on('Emojot Broadcast Event', function (msg) {
                callback(msg, true);
            });
        };

        var connectSensorRedirectChannel = function (callback) {

            var sensorRedirectChannel = io.connect("https://emojot.com/sensorRedirectChannel", {
                'timeout': 2000,
                'reconnection': true,
                'reconnectionDelay': 1000,
                'reconnectionDelayMax': 5000,
                'reconnectionAttempts': 5
            });

            sensorRedirectChannel.once('connect', function () {
                console.log("SensorRedirectChannel Connect...");
                sensorRedirectChannel.emit('register', {client_data: client_data});
            });

            sensorRedirectChannel.on('reconnecting', function () {
                console.log("SensorRedirectChannel Reconnect...");
            });

            sensorRedirectChannel.on('reconnect', function () {
                console.log("SensorRedirectChannel Reconnect...");
                sensorRedirectChannel.emit('register', {client_data: client_data});
            });

            sensorRedirectChannel.on('error', function () {
                console.log("Error while connecting to connect to the Emojot Notification Service.");
            });

            sensorRedirectChannel.on('reconnect_error', function () {
                console.log("Error while reconnecting to connect to the Emojot Notification Service.");
            });

            sensorRedirectChannel.on('reconnect_failed', function () {
                console.log("Failed to connect to the Emojot Notification Service. Please reload the sensor!", "Error");
            });

            sensorRedirectChannel.on('disconnect', function () {
                console.log("SensorRedirectChannel disconnected. Connecting Back...");
            });

            sensorRedirectChannel.on('Emojot Broadcast Event', function (msg) {
                callback(msg, true);
            });
        };

        return {
            connectEngagementChannel: connectEngagementChannel,
            connectSensorUpdateChannel: connectSensorUpdateChannel,
            connectSensorRedirectChannel:connectSensorRedirectChannel
        }

    }]);
})();