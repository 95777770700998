(function () {
    angular.module('mobileApp').service('enterpriseHierarchyDataService', ['$rootScope', '$http', '$cookies', function ($rootScope, $http, $cookies) {

        var getEmoSignatureModelDetails = function (tries, callback) {
            $http({
                url: 'https://sensorapi.emojot.com/v1/emoSignatures',
                method: 'GET',
                params: {
                    id : $rootScope.emoSignatureModelId,
                    companyId : $rootScope.companyID
                },
                headers: {'x-api-key': $rootScope.sensorAPIKey}
            }).then(function (data) {
                if(data.data){
                    $rootScope.emoSignatureHierarchy = data.data.result[0].model;
                    if(callback) {
                        callback(true);
                    }
                }
            }, function (status) {
                if (callback) {
                    callback(false);
                }
            });
        };

        return {
            getEmoSignatureModelDetails : getEmoSignatureModelDetails
        }
    }]);
})();