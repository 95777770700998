(function () {
    angular.module('mobileApp').service('sensorUpdateService', [ '$rootScope', '$http', '$cookies', '$window','$sce', 'socketIOConnectionService', 'sensorDataLoaderService', 'utilityProviderService','conditionalLibraryLoaderService', 'systemLoggerService',
        function ($rootScope, $http, $cookies, $window, $sce, socketIOConnectionService, sensorDataLoaderService, utilityProviderService, conditionalLibraryLoaderService, systemLoggerService) {

            if($rootScope.mobilePushEnabled) {
                    setTimeout(function () {
                        connectToChannels(0);
                    }, 3000);
            }else{
                $rootScope.$on('Page Loading Complete', function () {
                    if($rootScope.mobilePushEnabled) {
                        connectToChannels(0);
                    }
                });
            }


            var performSensorUpdateCall = function (tries) {
                var deviceID = utilityProviderService.tryJsonParse($cookies.get('deviceID'));

                var requestParams = {
                    deviceID: deviceID,
                    userID: $rootScope.userID,
                    embedThemes: true
                };

                if ($window.shortUrl && $window.shortUrl!=="null") {
                    requestParams.shortUrl =  $window.shortUrl;
                } else {
                    requestParams.companyID = $rootScope.companyID;
                    requestParams.activityID = $rootScope.activityID;
                }

                $http({
                    url: 'https://sensorapi.emojot.com/v1/activity',
                    method: 'GET',
                    contentType: 'application/json',
                    params: requestParams,
                    headers: {'x-api-key': $rootScope.sensorAPIKey}
                }).then(function (data) {
                    console.log('success!', data);

                    ////hide thank you page
                    if(!$rootScope.actionInProgress) {
                        $rootScope.showThankYouPageNow = false; // hide thank you page
                        if ($rootScope.activityType === 'campaign' && $rootScope.doKIOSK && $rootScope.kioskImageURL) {
                            $rootScope.kioskImgShow = true;
                        }
                    }

                    var isPreviousAndNewActivityEqual = angular.equals(sensorDataLoaderService.load(), data.data.activities[0]);

                    if (!isPreviousAndNewActivityEqual) {
                        updateNewActivityData(data.data.activities[0]);
                    }

                }, function (status) {

                });

            };

            var updateNewActivityData = function (activityData, localizationDone) {
                // console.log(sensorDataLoaderService.getActivityProperty('publishedCharts'));
                // console.log(activityData.publishedCharts);
                sensorDataLoaderService.showHideSensorPagesBasedOnEhFields(activityData, $window.emoSignature, null, $window.matchingEmoSigRule)
                $rootScope.randomizedSensor = false;
                if(activityData.activityType == "campaign"){
                    var randomizedSensorGroups = sensorDataLoaderService.getSensorWithRandomizedQuestions(activityData.groups,true);
                    $rootScope.randomizedSensor = randomizedSensorGroups[0];
                }
                var sensorLogo = "https://storage.emojot.com/logos/sensor_logo/" + activityData.companyID + '_' + activityData._id + ".png";

                if(activityData['_id']) {
                    $rootScope.activityID = activityData['_id'];
                }

                if(activityData['companyID']) {
                    $rootScope.companyID = activityData['companyID'];
                }

                $rootScope.doKIOSK = activityData.doKIOSK;
                $rootScope.commonOAuthTokenAllowed = activityData.commonOAuthTokenAllowed;

                if(!$rootScope.doKIOSK&& $window.doKIOSKRequest){
                    $rootScope.doKIOSK=true;
                }

                if($rootScope.doKIOSK&& !$window.doKIOSKRequest){
                    $rootScope.doKIOSK=false;
                }

                $rootScope.activityType = activityData.activityType;
                $rootScope.isHideUIComponent = activityData.hideUIComponent;
                $rootScope.hideUIComponents = activityData.uiComponents;

                $rootScope.advancedGeoLocationEnabled = activityData.advancedGeoLocationEnabled;
                if($rootScope.doKIOSK && ($rootScope.kioskImageURL != activityData.kioskImageURL )){
                   var kioskImageURL = activityData.kioskImageURL;
                   $rootScope.$broadcast('kiosk-img-update', {kioskImageURL: kioskImageURL});
                  }
                //$rootScope.kioskImageURL = activityData.kioskImageURL;
                $rootScope.emoticonPlacement = activityData.emoticonPlacement;
                $rootScope.combineSubmitEnabled = activityData.combineSubmit;
                $rootScope.showEmojis = activityData.showEmojis;
                $rootScope.engagementEnabled = activityData.engagement.enabled;
                $rootScope.mobilePushEnabled = activityData.mobileAutoRefreshEnabled;
                $rootScope.automaticEmotingEnabled = false;
                if (activityData['automaticEmotingEnabled']) {
                    $rootScope.automaticEmotingEnabled = true;
                }
                if(activityData['showNumbering']) {
                    $rootScope.showNumbering = activityData['showNumbering'];
                }
                else{
                    $rootScope.showNumbering = false;
                }
                if (activityData['activityTitle']) {
                    $rootScope.activityTitle = activityData['activityTitle'];
                    $rootScope.activityTitle = sensorDataLoaderService.processSensorTitleWithEmoSignatures($rootScope.activityTitle);
                } else {
                    $rootScope.activityTitle = activityData.activityName;
                }

                if(localizationDone){
                    if(activityData['customLocalizations']){
                        $rootScope.customLocalizations = activityData.customLocalizations;
                    }else{
                        $rootScope.customLocalizations = undefined;
                    }
                }

                if(activityData['emoSignatureDataPriority']){
                    $rootScope.emoSignatureDataPriority=activityData['emoSignatureDataPriority'];
                }

                if(activityData['newProfileCreationWarning']){
                    $rootScope.newProfileCreationWarning=activityData['newProfileCreationWarning'];
                }else{
                    $rootScope.newProfileCreationWarning=null;
                }

                if(activityData['newProfileCreationReload']){
                    $rootScope.newProfileCreationReload=activityData['newProfileCreationReload'];
                }else{
                    $rootScope.newProfileCreationReload=null;
                }

                if(activityData['newProfileCreationReject']){
                    $rootScope.newProfileCreationReject=activityData['newProfileCreationReject'];
                }else{
                    $rootScope.newProfileCreationReject = null;
                }

                if(activityData['stopRemapUserIDOnKIOSK']){
                    $rootScope.stopRemapUserIDOnKIOSK=activityData['stopRemapUserIDOnKIOSK'];
                }else{
                    $rootScope.stopRemapUserIDOnKIOSK = false;
                }

                if(activityData['lastResponsePopulate']){
                    $rootScope.lastResponsePopulate=activityData['lastResponsePopulate'];
                }else{
                    $rootScope.lastResponsePopulate = null;
                }

                if(activityData['profileDataFieldConversion']){
                    $rootScope.profileDataFieldConversion=activityData['profileDataFieldConversion'];
                }else{
                    $rootScope.profileDataFieldConversion = null;
                }

                if(activityData['profileDataBasedScoreCalculation']){
                    $rootScope.profileDataBasedScoreCalculation=activityData['profileDataBasedScoreCalculation'];
                }else{
                    $rootScope.profileDataBasedScoreCalculation = null;
                }

                if(activityData['resetTimeoutForIdling']) {
                    $rootScope.resetTimeoutForIdling = activityData['resetTimeoutForIdling'] === 'none'? 0 : Math.ceil(parseFloat(activityData['resetTimeoutForIdling']) * 60 * 1000); //convert to ms
                }

                if(activityData['responseLimitingTime']){
                    $rootScope.responseLimitingTime=activityData['responseLimitingTime'];
                }else{
                    $rootScope.responseLimitingTime = null;
                }

                if(activityData['profileDateBasedLogic']){
                    $rootScope.profileDateBasedLogic=activityData['profileDateBasedLogic'];
                }else{
                    $rootScope.profileDateBasedLogic = null;
                }

                //customer accordion
                if (activityData.customAccordionHeaders) {
                    if (activityData['customAccordionHeaders'].youTubeBuzz) {
                        $rootScope.customeAccordionYoutubeBuzz = activityData['customAccordionHeaders'].youTubeBuzz;
                    } else {
                        $rootScope.customeAccordionYoutubeBuzz = "YOUTUBE BUZZ";
                    }

                    if (activityData['customAccordionHeaders'].geoMap) {
                        $rootScope.customeAccordionGeoMap = activityData['customAccordionHeaders'].geoMap;
                    } else {
                        $rootScope.customeAccordionGeoMap = "GEO MAP";
                    }

                    if (activityData['customAccordionHeaders'].pulseMeter) {
                        $rootScope.customeAccordionPulseMeter = activityData['customAccordionHeaders'].pulseMeter;

                    } else {
                        $rootScope.customeAccordionPulseMeter = "EMOTE PULSE";

                    }

                    if (activityData['customAccordionHeaders'].feedback) {
                        $rootScope.customeAccordionFeedback = activityData['customAccordionHeaders'].feedback;
                    } else {
                        $rootScope.customeAccordionFeedback = "TEXT FEEDBACK";
                    }

                    if (activityData['customAccordionHeaders'].twitterBuzz) {
                        $rootScope.customeAccordionTwitterBuzz = activityData['customAccordionHeaders'].twitterBuzz;
                    } else {
                        $rootScope.customeAccordionTwitterBuzz = "TWITTER BUZZ";
                    }

                    if (activityData['customAccordionHeaders'].facebookBuzz) {
                        $rootScope.customeAccordionFacebookBuzz = activityData['customAccordionHeaders'].facebookBuzz;

                    } else {
                        $rootScope.customeAccordionFacebookBuzz = "FACEBOOK BUZZ";

                    }

                    if (activityData['customAccordionHeaders'].instagramBuzz) {
                        $rootScope.customeAccordionInstagramBuzz = activityData['customAccordionHeaders'].instagramBuzz;

                    } else {
                        $rootScope.customeAccordionInstagramBuzz = "INSTAGRAM BUZZ";

                    }

                    if (activityData['customAccordionHeaders'].statusBuzz) {
                        $rootScope.customeAccordionStatusBuzzEnabled = activityData['customAccordionHeaders'].statusBuzz;

                    } else {
                        $rootScope.customeAccordionStatusBuzzEnabled = "STATUS BUZZ";

                    }

                    $rootScope.customeAccordionUStreamBuzz = activityData['customAccordionHeaders'].uStreamBuzz;
                    $rootScope.customeAccordionTimeLine = activityData['customAccordionHeaders'].timeLine;
                    $rootScope.customeAccordionShare = activityData['customAccordionHeaders'].share;
                    $rootScope.customeAccordionCommentBuzz = activityData['customAccordionHeaders'].commentBuzz;
                    $rootScope.customeAccordionImageBuzz = activityData['customAccordionHeaders'].imageBuzz;

                } else {
                    $rootScope.customeAccordionGeoMap = "GEO MAP";
                    $rootScope.customeAccordionTimeLine = "EMOTE TIME SERIES";
                    $rootScope.customeAccordionPulseMeter = "EMOTE PULSE";
                    $rootScope.customeAccordionFeedback = "TEXT FEEDBACK";
                    $rootScope.customeAccordionTwitterBuzz = "TWITTER BUZZ";
                    $rootScope.customeAccordionFacebookBuzz = "FACEBOOK BUZZ";
                    $rootScope.customeAccordionInstagramBuzz = "INSTAGRAM BUZZ";
                }

                if (activityData['sensorLogo']) {
                    $rootScope.sensorLogo = activityData['sensorLogo']['sensorLogoName'];
                }

                if ($rootScope.sensorLogo) {
                    sensorLogo = "https://storage.emojot.com/logos/sensor_logo/" + $rootScope.sensorLogo + ".png";
                    $rootScope.companyLogo = sensorLogo + "?" + (new Date).getTime();
                } else {
                    verifyImageURL(sensorLogo, function (imageExists) {
                        if (imageExists === true) {
                            $rootScope.companyLogo = sensorLogo + "?" + (new Date).getTime();
                        } else {
                            $rootScope.companyLogo = activityData['companyLogo'];
                        }
                    });
                }

                //sensorDataLoaderService.getActivityProperty("companyLogo").value = activityData.companyLogo;

                if(activityData.displayTags){
                    $rootScope.isEmoTagsEnabled = true;
                    $rootScope.emoTagsDescription = activityData.emoTagsDescription;
                    $rootScope.emoTags = activityData.emotags;
                }else{
                    $rootScope.isEmoTagsEnabled = false;
                }

                if($rootScope.emoticonPlacement === 'multiple'){
                    $rootScope.emotesPerRow = parseInt(activityData.emotesPerRow);
                }

                if ($rootScope.resources != activityData.resources) {
                    updateNewResources(activityData.resources);
                }

                if ($rootScope.drillDownGroupings != activityData.groups) {
                    $rootScope.drillDownGroupings = activityData.groups;
                }

                if(activityData.drillDownPaging){
                    $rootScope.drillDownPagingEnabled = true;
                    $rootScope.drillDownPagingOptions = activityData.drillDownPagingOptions;
                }else{
                    $rootScope.drillDownPagingEnabled = false;
                }

                //emoSignature Hierarchy
                $rootScope.enterpriseHierarchyCapturePrevious = $rootScope.enterpriseHierarchyCapture;
                $rootScope.enterpriseHierarchyCapture = activityData['enterpriseHierarchyCapture'];

                $rootScope.emoSignatureModelIdPrevious = $rootScope.emoSignatureModelId;
                $rootScope.emoSignatureModelId = activityData['enterpriseHierarchyModelId'];

                if(!$rootScope.enterpriseHierarchyCapture || !$rootScope.emoSignatureModelId ){
                    $rootScope.emoSignatureHierarchy = null;
                }

                if ($rootScope.automaticMoveToNextPage != activityData.automaticMoveToNextPage) {
                    $rootScope.automaticMoveToNextPage = activityData.automaticMoveToNextPage;
                }

                if(activityData.drillDownPagingOptions && activityData.drillDownPagingOptions.pageLogics) {
                    if(!$rootScope.drillDownPagingOptions.pageLogics){
                        $rootScope.drillDownPagingOptions['pageLogics'] = [];
                    }
                    $rootScope.drillDownPagingOptions.pageLogics = activityData.drillDownPagingOptions.pageLogics;
                }

                if (sensorDataLoaderService.getActivityProperty("percepticDrillDowns") != activityData.percepticDrillDowns||localizationDone) {
                    var drillDownDisabledArr=[];
                    for(var i=0;i< $rootScope.percepticDrillDowns.length;i++){
                        if($rootScope.percepticDrillDowns[i].disabledPD){
                            drillDownDisabledArr.push($rootScope.percepticDrillDowns[i].id);
                        }
                    }
                    $rootScope.percepticDrillDowns = activityData.percepticDrillDowns;

                    for(var i=0;i< $rootScope.percepticDrillDowns.length;i++){
                        for(var j=0;j< drillDownDisabledArr.length;j++){
                            if($rootScope.percepticDrillDowns[i].id==drillDownDisabledArr[j]){
                                $rootScope.percepticDrillDowns[i].disabledPD=true;
                                break;
                            }
                        }
                    }
                    sensorDataLoaderService.createPerceptionDrillDownMap(activityData.percepticDrillDowns);
                    //sensorDataLoaderService.getActivityProperty("percepticDrillDowns").value = activityData.percepticDrillDowns;
                    //console.log(JSON.stringify(activityData.percepticDrillDowns));
                    $rootScope.$broadcast('Sensor update Data Loaded', {
                        localizationSensorUpdate : localizationDone
                    });
                }else{
                    if(sensorDataLoaderService.getActivityProperty("drillDownPaging") != activityData.drillDownPaging){
                        $rootScope.percepticDrillDowns = activityData.percepticDrillDowns;
                        $rootScope.$broadcast('Sensor update Data Loaded');
                    }
                }

                if(activityData.capturePicture || activityData.responderImageCapture){
                    var webCamAlreadyInitialied = false;

                    if($rootScope.responderImageCapture || $rootScope.isCapturePicture){
                        webCamAlreadyInitialied = true;
                    }
                    if(!$rootScope.isCapturePicture && activityData.capturePicture){
                        $rootScope.isCapturePicture = true;
                    }

                    if(!$rootScope.responderImageCapture && activityData.responderImageCapture){
                        $rootScope.responderImageCapture = true;
                        $rootScope.responderImageCaptureOptions = activityData['responderImageCaptureOptions'];
                    }
                    if(!webCamAlreadyInitialied){
                        $rootScope.$broadcast('START_WEBCAM');
                    }
                }else{
                    $rootScope.isCapturePicture = false;
                    $rootScope.$broadcast('STOP_WEBCAM');

                    if($rootScope.responderImageCapture){
                        $rootScope.responderImageCapture = false;
                        $rootScope.$broadcast('stop periodic capturing')
                    }
                }

                if (activityData.SMSharingEnabled) {
                    $rootScope.isSMSharing = activityData.SMSharingEnabled;
                    $rootScope.hashTags = activityData.hashTags;
                    $rootScope.SMSharingImage = activityData.SMSharingImage;

                    if (sensorDataLoaderService.getActivityProperty("ShareableSMChannels") != activityData.ShareableSMChannels) {

                        $rootScope.isFacebookSM = false;
                        $rootScope.isTwitterSM = false;
                        $rootScope.isGoogsappSM = false;
                        $rootScope.isVibeleSM = false;
                        $rootScope.isWhatrSM = false;

                        $rootScope.ShareableSMChannels = activityData.ShareableSMChannels;

                        if ($rootScope.ShareableSMChannels) {
                            var length = $rootScope.ShareableSMChannels.length;

                            for (var count = 0; count < length; count++) {
                                if ($rootScope.ShareableSMChannels[count] === 'facebook') {
                                    $rootScope.isFacebookSM = true;
                                } else if ($rootScope.ShareableSMChannels[count] === 'twitter') {
                                    $rootScope.isTwitterSM = true;
                                } else if ($rootScope.ShareableSMChannels[count] === 'g+') {
                                    $rootScope.isGoogleSM = true;
                                } else if ($rootScope.ShareableSMChannels[count] === 'whatsapp') {
                                    $rootScope.isWhatsappSM = true;
                                } else if ($rootScope.ShareableSMChannels[count] === 'viber') {
                                    $rootScope.isViberSM = true;
                                }
                            }
                        }
                    }
                } else {
                    $rootScope.isSMSharing = false;
                    $rootScope.isFacebookSM = false;
                    $rootScope.isTwitterSM = false;
                    $rootScope.isGoogleSM = false;
                    $rootScope.isWhatsappSM = false;
                    $rootScope.isViberSM = false;
                }

                if ($rootScope.loyaltyFields != activityData.loyaltyFields) {
                    if ( activityData.loyaltyFields.length !=0) {
                        $rootScope.loyaltyFields  = activityData.loyaltyFields;
                        $rootScope.loyaltyMessage =  $sce.trustAsHtml(activityData.loyaltyMessage);
                    } else {
                        $rootScope.loyaltyFields  = activityData.loyaltyFields;
                        $rootScope.showLoyaltyCapture = false;
                    }
                }

                $rootScope.$broadcast('Loyalty Fields Loaded');


                $rootScope.clientBasedTokenValidation = activityData['tokenBasedValidation']? activityData['clientBasedTokenValidation'] : null;
                $rootScope.showTokenCapture = $rootScope.clientBasedTokenValidation ? $rootScope.clientBasedTokenValidation['validatedAt'] == 'start' && !($rootScope.loyaltyFields&& $rootScope.loyaltyFields.length > 0 && $rootScope.doKIOSK) : false;

                if(activityData.commentEnabled) {
                    if (!angular.isUndefined(activityData.commentTheme)) {
                        try {
                            if (!angular.isUndefined(sensorDataLoaderService.getActivityProperty("commentTheme"))) {
                                if (sensorDataLoaderService.getActivityProperty("commentTheme").commentIcons != activityData.commentTheme.commentIcons) {
                                    //if (activityData.commentEnabled === true) {
                                        var newCommentObjects = activityData.commentTheme.commentIcons;
                                        updateFeedbackPanel(newCommentObjects, true);
                                    /*} else if (activityData.commentEnabled === false) {
                                        var oldCommentObjects = activityData.commentTheme.commentIcons;
                                        updateFeedbackPanel(oldCommentObjects, false);
                                    }*/
                                }

                            } else {
                                /*if (activityData.commentEnabled) {*/
                                    var newCommentObjects = activityData.commentTheme.commentIcons;
                                    updateFeedbackPanel(newCommentObjects, true);
                                /*} else if (activityData.commentEnabled === false) {
                                    /!*var oldCommentObjects = activityData.commentTheme.commentIcons;
                                     updateFeedbackPanel(oldCommentObjects, false);*!/
                                }*/
                            }
                        } catch (ex) {
                            console.log(ex);
                        }
                    }
                }else{
                    if(!angular.isUndefined(sensorDataLoaderService.getActivityProperty("commentTheme")) && $rootScope.isCommentEnabled){
                        $rootScope.isCommentEnabled = false;
                    }
                }


                if(activityData.drillDownPagingOptions && angular.isUndefined(activityData.drillDownPagingOptions.showPulseMeterChart)){
                    activityData.drillDownPagingOptions.showPulseMeterChart = false;
                }
                if(activityData.drillDownPagingOptions && angular.isUndefined($rootScope.drillDownPagingOptions.showGeoMapChart)){
                    activityData.drillDownPagingOptions.showGeoMapChart = false;
                }

                $rootScope.geoMapEnabled = activityData.publishedCharts && activityData.publishedCharts.types.indexOf('PHM') !== -1 || (activityData.drillDownPagingOptions && activityData.drillDownPagingOptions.showGeoMapChart);

                if(activityData.publishedCharts || activityData.drillDownPaging && activityData.drillDownPagingOptions) {

                    if(activityData.publishedCharts) {
                        if (sensorDataLoaderService.getActivityProperty('publishedCharts') != activityData.publishedCharts) {

                            if ($.inArray('PHM', activityData.publishedCharts.types) != -1) {
                                $rootScope.generalGeoMapEnabled = true;

                                var mapOptions = activityData.publishedCharts.options.PHM;
                                updateGeoMapPanel(mapOptions, true, activityData);
                            } else {
                                $rootScope.generalGeoMapEnabled = false;
                                if(activityData.drillDownPagingOptions.showGeoMapChart){
                                    var mapOptions = {focusCriteria: "emoterLoc",mapType: "emoteAverage",zoomLevel: 3};
                                    updateGeoMapPanel(mapOptions, true, activityData);
                                } else {
                                    var oldMapOptions = null;
                                    updateGeoMapPanel(oldMapOptions, false, null);
                                }
                            }

                            if ($.inArray('EPM', activityData.publishedCharts.types) != -1) {
                                $rootScope.generalEmotePulseEnabled = true;
                                $rootScope.publishedCharts = activityData.publishedCharts;
                                updateEmotePulseMeter(true, activityData);

                            } else if (activityData.publishedCharts === null || $.inArray('EPM', activityData.publishedCharts.types) == -1) {
                                $rootScope.generalEmotePulseEnabled = false;
                                if(activityData.drillDownPagingOptions.showPulseMeterChar){
                                    updateEmotePulseMeter(true, activityData);
                                } else{
                                    updateEmotePulseMeter(false, null);
                                }
                            }

                            if ($.inArray('PTL', activityData.publishedCharts.types) != -1) {
                                updateEmoteTimeSeries(true, activityData);
                            } else if (activityData.publishedCharts === null || $.inArray('PTL', activityData.publishedCharts.types) == -1) {
                                updateEmoteTimeSeries(false, null);
                            }

                            if ($.inArray('CB', activityData.publishedCharts.types) != -1) {
                                $rootScope.commentBuzzEnabled = true;
                            }

                            if ($.inArray('StatusBuzz', activityData.publishedCharts.types) != -1) {
                                $rootScope.statusBuzzEnabled = true;
                            }

                        }
                    } else {
                            $rootScope.generalEmotePulseEnabled = false;
                            $rootScope.generalGeoMapEnabled = false;

                            $rootScope.publishedCharts = {
                                options:{},
                                types: []
                            }

                            if(activityData.drillDownPagingOptions.showPulseMeterChart) {
                                $rootScope.publishedCharts.options['EPM'] = {typeChart: true, typeCount: false};
                                updateEmotePulseMeter(true, activityData);
                            } else {
                                updateEmotePulseMeter(false, null);
                            }

                            if(activityData.drillDownPagingOptions.showGeoMapChart) {
                                $rootScope.publishedCharts.options['PHM'] = {focusCriteria: "emoterLoc",mapType: "emoteAverage",zoomLevel: 3};
                                var mapOptions = {focusCriteria: "emoterLoc",mapType: "emoteAverage",zoomLevel: 3};
                                updateGeoMapPanel(mapOptions, true, activityData);
                            }else {
                                updateGeoMapPanel(null, false, null);
                            }
                    }
                } else{
                    updateGeoMapPanel(null, false, null);
                    updateEmotePulseMeter(false, null);
                    updateEmoteTimeSeries(false, null);
                    $rootScope.commentBuzzEnabled = false;
                    $rootScope.statusBuzzEnabled = false;
                    $rootScope.generalEmotePulseEnabled = false; //user for show only inside page. not thankyou page
                    $rootScope.generalGeoMapEnabled = false; //user for show only inside page. not thankyou page
                }

                if(activityData.engagement.enabled || activityData.mobileAutoRefreshEnabled){
                    conditionalLibraryLoaderService.loadLibraries();
                }


                /*if(localizationDone){
                    if($rootScope.drillDownPagingOptions && $rootScope.drillDownPagingOptions.pageLogics){
                        updateThankYouPageLogic()
                    }
                } else{*/
                //}


                if(angular.isDefined(activityData.toasters) && Object.keys(activityData.toasters).length > 0){
                    var alertDefaultMessages = angular.copy($rootScope.alertMessagesDefault)
                    angular.forEach($rootScope.alertMessages,function (value,key) {
                        if(activityData.toasters[key]){
                            $rootScope.alertMessages[key] = activityData.toasters[key];
                        }else{
                            $rootScope.alertMessages[key] = alertDefaultMessages[key];
                        }
                    })
                } else {
                    $rootScope.alertMessages = angular.copy($rootScope.alertMessagesDefault);
                }

                if(angular.isDefined(activityData.draftResponse) && activityData.draftResponse){
                    $rootScope.draftResponse = activityData.draftResponse;
                }
                if(angular.isDefined(activityData.ehGenerationRules) && activityData.ehGenerationRules){
                    $rootScope.ehGenerationRules = activityData.ehGenerationRules;
                }

                if(angular.isDefined(activityData.demographicPrePopulate) && activityData.demographicPrePopulate){
                    $rootScope.demographicPrePopulate = activityData.demographicPrePopulate;
                }

                if(activityData.groups){
                    angular.forEach(activityData.groups,function (group,index) {
                        if(group.mediaFiles){
                            if(group.mediaFiles.maxFileCount){
                                $rootScope.mediaFiles[group.id]['maxFileCount'] = group.mediaFiles.maxFileCount
                            }
                            if(group.mediaFiles.maxFileSize){
                                $rootScope.mediaFiles[group.id]['maxFileSize'] = group.mediaFiles.maxFileSize
                            }
                            if(group.mediaFiles.minFileCount){
                                $rootScope.mediaFiles[group.id]['minFileCount'] = group.mediaFiles.minFileCount
                            }

                            $rootScope.mediaFiles[group.id]['supportTypes'] = group.mediaFiles.supportTypes || null;
                            $rootScope.mediaFiles[group.id]['title'] = group.mediaFiles.title
                            $rootScope.mediaFiles[group.id]['required'] = group.mediaFiles.required || false;
                        }
                    })
                }
                //updateNewEmoTag(activityData);

                //updatePublishAnalytics(activityData)
                if (!localizationDone) {
                    $rootScope.$broadcast('Localization Sensor Update');
                }

                $rootScope.intermediateSubmit = false;
                if(angular.isDefined(activityData.intermediateSubmit)){
                    $rootScope.intermediateSubmit = activityData.intermediateSubmit;
                }

                //set sensor attributes for logging
                systemLoggerService.setGlobalSensorAttributes();
            };

            var updateFeedbackPanel = function (newCommentObjects, isCommentEnabled) {
                $rootScope.$broadcast('text-feedback-update', {
                    newCommentObject: newCommentObjects,
                    isCommentEnabled: isCommentEnabled
                });
            };

            var updateGeoMapPanel = function (mapOptions, isGeoMapEnabled, activityData) {
                $rootScope.$broadcast('geo-map-update', {mapOptions: mapOptions, isGeoMapEnabled: isGeoMapEnabled, activityData: activityData});
            };

            var updateEmotePulseMeter = function (pulseMeterEnabled, activityData) {
                $rootScope.$broadcast('emote-pulse-update', {pulseMeterEnabled: pulseMeterEnabled, activityData: activityData});
            };

            var updateEmoteTimeSeries = function (isEmoteTimeSeriesEnabled, activityData) {
                $rootScope.$broadcast('emote-time-series-update', {isEmoteTimeSeriesEnabled: isEmoteTimeSeriesEnabled, activityData: activityData});
            };

            /*
             * Update new resource when changed
             * */
            var updateNewResources = function (resources) {
                $rootScope.$broadcast('resource-update', {resource: resources});

                /*if(angular.isDefined(resources) && resources != null) {
                 for (var count = 0; count < resources.length; count++) {

                 if (resources[count].type === 'fb') {
                 if (angular.isDefined(resources[count].src)) {
                 var activityResourcesFB = angular.element(document.querySelector("#activityResourcesFB"));
                 activityResourcesFB.css(
                 {
                 display: 'block'
                 });

                 var activityResourcesContentFB = angular.element(document.querySelector('#activityResourcesContentFB'));

                 activityResourcesContentFB.css(
                 {
                 width: '100%',
                 display: 'table'

                 });

                 activityResourcesContentFB.html(resources[count].src);
                 $rootScope.facebookBuzzSrc = resources[count].src;
                 facebookDone = true;
                 console.log(resources[count].src);
                 }
                 }
                 else if (resources[count].type == 'twitter') {
                 if (angular.isDefined(resources[count].src)) {

                 var activityResourcesTwitter = angular.element(document.querySelector("#activityResourcesTwitter"));
                 activityResourcesTwitter.css(
                 {
                 display: 'block'
                 }
                 );

                 var activityResourcesContentTwitter = angular.element(document.querySelector('#activityResourcesContentTwitter'));

                 activityResourcesContentTwitter.css(
                 {
                 width: '100%',
                 display: 'table'

                 });

                 var twitterContent = activityResourcesContentTwitter.children("twitter-timeline");

                 if (angular.isDefined(twitterContent) && twitterContent != null) {
                 twitterContent.children().remove();
                 }

                 $rootScope.twitterBuzzSrc = resources[count].src.substring(0, resources[count].src.indexOf("<script>"));
                 twitterContent.append(angular.element(resources[count].src.substring(0, resources[count].src.indexOf("<script>"))));

                 console.log(resources[count].src);
                 console.log(resources[count].src.substring(0, resources[count].src.indexOf("<script>")));
                 twitterDone = true;
                 }
                 }
                 else if (resources[count].type === 'instagram') {
                 if (angular.isDefined(resources[count].src)) {

                 var resourceInstagram = angular.element(document.querySelector("#activityResourcesContentInstagram"));

                 if (angular.isDefined(resourceInstagram) && resourceInstagram != null) {
                 resourceInstagram.children().remove();
                 }

                 resourceInstagram.append(resources[count].src);
                 $rootScope.instagramBuzzSrc = resources[count].src;
                 instagramDone = true;
                 console.log(resources[count].src);
                 }
                 }
                 else if (resources[count].type === 'youtube') {
                 if (angular.isDefined(resources[count].src)) {
                 // TODO : isvideo val function
                 var isVideoEval = true;

                 var youtubeFullString = resources[count].src;
                 var youtubeURLExtract = youtubeFullString.substring(youtubeFullString.indexOf("src=") + 4, youtubeFullString.indexOf("frameborder")).replace(/["']/g, "");
                 var youtubeID = youtubeURLExtract.replace(/["']/g, "").split("/")[4];


                 $rootScope.youtubeBuzzSrc = String(youtubeURLExtract);
                 youtubeDone = true;
                 console.log(resources[count].src);
                 console.log(youtubeID);
                 console.log(youtubeURLExtract);
                 }
                 }
                 else if (resources[count].type === 'ustream') {
                 if (angular.isDefined(resources[count].src)) {

                 $rootScope.uStreamBuzzSrc = resources[count].src;
                 ustreamDone = true;
                 console.log(resources[count].src);
                 }
                 }
                 else if (resources[count].type === 'image') {
                 if (angular.isDefined(resources[count].src)) {

                 var isVideoEval = false;

                 var activityResourcesImage = angular.element(document.querySelector("#activityResourcesImage"));

                 if (isVideoEval) {
                 activityResourcesImage = angular.element(document.querySelector("#activityResourcesImageTop"));
                 }
                 activityResourcesImage.css(
                 {
                 display: 'block'
                 }
                 );

                 var activityResourcesContentImage = angular.element(document.querySelector('#activityResourcesContentImage'));

                 // TODO : If check logic not added
                 if (isVideoEval) {
                 activityResourcesContentImage = angular.element(document.querySelector("#activityResourcesContentImageTop"));
                 }

                 if (angular.isDefined(activityResourcesContentImage) && activityResourcesContentImage != null) {
                 activityResourcesContentImage.children().remove();
                 }

                 $rootScope.imageBuzzSrc = resources[count].src;
                 imageDone = true;
                 console.log(resources[count].src);
                 }
                 }

                 }
                 removeUnwantedResources();
                 }*/
            };

             function connectToChannels(tries){
                 try {
                     socketIOConnectionService.connectSensorUpdateChannel(function (msg, status) {
                         console.log(msg + ".." + status);
                         performSensorUpdateCall(0);
                     });

                     socketIOConnectionService.connectSensorRedirectChannel(function (msg, status) {
                         console.log(msg + ".." + status);
                         if (msg.redirectUrl) {
                             if (utilityProviderService.validateUrl(msg.redirectUrl)) {
                                 $window.location.href = msg.redirectUrl;
                             }
                         }
                     });
                 }
                 catch (ex){
                     if (ex instanceof ReferenceError) {
                         if(tries < 5) {
                             tries++;
                             setTimeout(function () {
                                 connectToChannels(tries);
                             }, 5000);
                         }else{
                             console.log('Socket IO ReferenceError');
                         }
                     } else {
                         console.log('sensor update channel connect error');
                     }
                 }
            };

            /*
             * Remove unwanted resources
             * */
            /*var removeUnwantedResources = function () {
             if(!facebookDone){
             $rootScope.facebookBuzzSrc = null;
             }
             if(!twitterDone){
             $rootScope.twitterBuzzSrc = null;
             }
             if(!instagramDone){
             $rootScope.instagramBuzzSrc = null;
             }
             if(!youtubeDone){
             $rootScope.youtubeBuzzSrc = null;
             }
             if(!ustreamDone){
             $rootScope.uStreamBuzzSrc = null;
             }
             if(!imageDone){
             $rootScope.imageBuzzSrc = null;
             }
             };*/

            /*
             * Update emo tag drop down items
             * */
            /*var updateNewEmoTag = function (activityData) {

             };*/

            /*
             * Update analytics view
             * */
            /*var updatePublishAnalytics = function (activityData) {
             if(activityData.publishAnalytics){
             if(activityData.publishedCharts != null){
             var enabledChartTypes = activityData.publishedCharts.types;
             var noOFEnabledChart = activityData.publishedCharts.type.length;
             var currentPublishCharts = sensorDataLoaderService.getActivityProperty("publishedCharts").types
             for(var x=0;x<noOFEnabledChart;x++){

             if(activityData.publishedCharts.types[x] == "EPM"){
             if(currentPublishCharts.indexOf('EPM') == -1){

             }else{

             }
             }
             if(activityData.publishedCharts.types[x] == "" ){

             }


             }
             }
             else{

             }

             var ptlEnabled = false;
             var phmEnabled = false;
             var epmEnabled = false;

             var ebEnabled = false;
             var cbEnabled = false;

             // TODO :  chart high chart
             var isVideoEval = true;

             for (var i = 0; i < enabledChartTypes.length; i++) {

             if (enabledChartTypes[i] === 'PTL') {
             if (!isVideoEval) {
             if (highchartsLoaded) {
             loadSelfAnalyticGraph(selectedEvent[0].companyID, selectedEvent[0]._id, true);
             } else {
             highchartsLoadHandler(selectedEvent[0].companyID, selectedEvent[0]._id, true, "PTL");
             }
             ptlEnabled = true;
             }
             } else if (enabledChartTypes[i] === 'PHM') {
             if (typeof previousHeatMapAdminOptions === "undefined" || ( previousHeatMapAdminOptions.mapType != selectedEvent[0].publishedCharts.options.PHM.mapType)) {
             if (mapLibsLoaded) {
             loadHeatMap(selectedEvent[0].companyID, selectedEvent[0]._id, false);
             } else {
             mapLoadHandler(selectedEvent[0].companyID, selectedEvent[0]._id, false);
             }
             } else {
             if (mapLibsLoaded) {
             loadHeatMap(selectedEvent[0].companyID, selectedEvent[0]._id, true);
             } else {
             mapLoadHandler(selectedEvent[0].companyID, selectedEvent[0]._id, true);
             }
             }

             previousHeatMapAdminOptions = selectedEvent[0].publishedCharts.options.PHM;
             phmEnabled = true;
             } else if (enabledChartTypes[i] === 'EPM') {
             if (!isVideoEval) {
             if (highchartsLoaded) {
             loadPulseMeter(selectedEvent[0].companyID, selectedEvent[0]._id, true);
             } else {
             highchartsLoadHandler(selectedEvent[0].companyID, selectedEvent[0]._id, true, "EPM");
             }
             epmEnabled = true;
             }
             } else if (enabledChartTypes[i] === 'EB') {
             //loadEmoteBuzz()
             ebEnabled = true;
             } else if (enabledChartTypes[i] === 'CB') {
             loadCommentBuzz(true);
             cbEnabled = true;
             }
             }
             }
             };*/
            return {
                updateNewActivityData:updateNewActivityData
            }
        }]);

})();