(function () {
    angular.module('mobileApp').service('utilityProviderService',['$cookies', '$http', function ($cookies, $http) {

        var tryJsonParse = function (key) {
            var value;
            try {
                value = JSON.parse(key);
            }
            catch(e){
                value = key;
            }
            return value;
        };

        var findQueryParam = function(url, field, callBack) {
            if(url.indexOf('?' + field + '=') != -1) {
                callBack(true);
            } else if (url.indexOf('&' + field + '=') != -1) {
                callBack(true);
            } else {
                callBack(false);
            }
        };

        var getParameterByName = function(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        if ( ! Array.isArray) {
            Array.isArray = function(arg) {
                return Object.prototype.toString.call(arg) === "[object Array]";
            };
        }

        if( ! Array.unique) {
            /* eslint-disable no-extend-native */
            Array.prototype.unique = function() {
                var a = [];
                for (var i=0, l=this.length; ia<l; i++) {
                    if (a.indexOf(this[i]) === -1) {
                        a.push(this[i]);
                    }
                }
                return a;
            };
        }

        var jsonLogic = {};
        var operations = {
            "==": function(a, b) {
                return a == b;
            },
            "===": function(a, b) {
                return a === b;
            },
            "!=": function(a, b) {
                return a != b;
            },
            "!==": function(a, b) {
                return a !== b;
            },
            ">": function(a, b) {
                return a > b;
            },
            ">=": function(a, b) {
                return a >= b;
            },
            "<": function(a, b, c) {
                return (c === undefined) ? a < b : (a < b) && (b < c);
            },
            "<=": function(a, b, c) {
                return (c === undefined) ? a <= b : (a <= b) && (b <= c);
            },
            "!!": function(a) {
                return jsonLogic.truthy(a);
            },
            "!": function(a) {
                return !jsonLogic.truthy(a);
            },
            "%": function(a, b) {
                return a % b;
            },
            "log": function(a) {
                console.log(a); return a;
            },
            "in": function(a, b) {
                if(typeof b.indexOf === "undefined") return false;
                return (b.indexOf(a) !== -1);
            },
            "cat": function() {
                return Array.prototype.join.call(arguments, "");
            },
            "+": function() {
                return Array.prototype.reduce.call(arguments, function(a, b) {
                    return parseFloat(a, 10) + parseFloat(b, 10);
                }, 0);
            },
            "*": function() {
                return Array.prototype.reduce.call(arguments, function(a, b) {
                    return parseFloat(a, 10) * parseFloat(b, 10);
                });
            },
            "-": function(a, b) {
                if(b === undefined) {
                    return -a;
                }else{
                    return a - b;
                }
            },
            "/": function(a, b) {
                if(b === undefined) {
                    return a;
                }else{
                    return a / b;
                }
            },
            "min": function() {
                return Math.min.apply(this, arguments);
            },
            "max": function() {
                return Math.max.apply(this, arguments);
            },
            "merge": function() {
                return Array.prototype.reduce.call(arguments, function(a, b) {
                    return a.concat(b);
                }, []);
            },
            "var": function(a, b) {
                var not_found = (b === undefined) ? null : b;
                var sub_props = String(a).split(".");
                var data = this;
                for(var i = 0; i < sub_props.length; i++) {
                    // Descending into data
                    data = data[sub_props[i]];
                    if(data === undefined) {
                        return not_found;
                    }
                }
                return data;
            },
            "missing": function() {
                /*
                 Missing can receive many keys as many arguments, like {"missing:[1,2]}
                 Missing can also receive *one* argument that is an array of keys,
                 which typically happens if it's actually acting on the output of another command
                 (like 'if' or 'merge')
                 */

                var missing = [];
                var keys = Array.isArray(arguments[0]) ? arguments[0] : arguments;

                for(var i = 0; i < keys.length; i++) {
                    var key = keys[i];
                    var value = jsonLogic.apply({"var": key}, this);
                    if(value === null || value === "") {
                        missing.push(key);
                    }
                }

                return missing;
            },
            "missing_some": function(need_count, options) {
                // missing_some takes two arguments, how many (minimum) items must be present, and an array of keys (just like 'missing') to check for presence.
                var are_missing = jsonLogic.apply({"missing": options}, this);

                if(options.length - are_missing.length >= need_count) {
                    return [];
                }else{
                    return are_missing;
                }
            },
            "method": function(obj, method, args) {
                return obj[method].apply(obj, args);
            },

        };

        jsonLogic.is_logic = function(logic) {
            return (
                logic !== null
                && typeof logic === "object"
                && ! Array.isArray(logic)
            );
        };

        /*
         This helper will defer to the JsonLogic spec as a tie-breaker when different language interpreters define different behavior for the truthiness of primitives.  E.g., PHP considers empty arrays to be falsy, but Javascript considers them to be truthy. JsonLogic, as an ecosystem, needs one consistent answer.

         Literal | JS    |  PHP  |  JsonLogic
         --------+-------+-------+---------------
         []      | true  | false | false
         "0"     | true  | false | true
         */
        jsonLogic.truthy = function(value) {
            if(Array.isArray(value) && value.length === 0) {
                return false;
            }
            return !! value;
        };

        jsonLogic.apply = function(logic, data) {
            // Does this array contain logic? Only one way to find out.
            if(Array.isArray(logic)) {
                return logic.map(function(l) {
                    return jsonLogic.apply(l, data);
                });
            }
            // You've recursed to a primitive, stop!
            if( ! jsonLogic.is_logic(logic) ) {
                return logic;
            }

            data = data || {};

            var op = Object.keys(logic)[0];
            var values = logic[op];
            var i;
            var current;

            // easy syntax for unary operators, like {"var" : "x"} instead of strict {"var" : ["x"]}
            if( ! Array.isArray(values)) {
                values = [values];
            }

            // 'if', 'and', and 'or' violate the normal rule of depth-first calculating consequents, let each manage recursion as needed.
            if(op === "if" || op == "?:") {
                /* 'if' should be called with a odd number of parameters, 3 or greater
                 This works on the pattern:
                 if( 0 ){ 1 }else{ 2 };
                 if( 0 ){ 1 }else if( 2 ){ 3 }else{ 4 };
                 if( 0 ){ 1 }else if( 2 ){ 3 }else if( 4 ){ 5 }else{ 6 };

                 The implementation is:
                 For pairs of values (0,1 then 2,3 then 4,5 etc)
                 If the first evaluates truthy, evaluate and return the second
                 If the first evaluates falsy, jump to the next pair (e.g, 0,1 to 2,3)
                 given one parameter, evaluate and return it. (it's an Else and all the If/ElseIf were false)
                 given 0 parameters, return NULL (not great practice, but there was no Else)
                 */
                for(i = 0; i < values.length - 1; i += 2) {
                    if( jsonLogic.truthy( jsonLogic.apply(values[i], data) ) ) {
                        return jsonLogic.apply(values[i+1], data);
                    }
                }
                if(values.length === i+1) return jsonLogic.apply(values[i], data);
                return null;
            }else if(op === "and") { // Return first falsy, or last
                for(i=0; i < values.length; i+=1) {
                    current = jsonLogic.apply(values[i], data);
                    if( ! jsonLogic.truthy(current)) {
                        return current;
                    }
                }
                return current; // Last
            }else if(op === "or") {// Return first truthy, or last
                for(i=0; i < values.length; i+=1) {
                    current = jsonLogic.apply(values[i], data);
                    if( jsonLogic.truthy(current) ) {
                        return current;
                    }
                }
                return current; // Last
            }


            // Everyone else gets immediate depth-first recursion
            values = values.map(function(val) {
                return jsonLogic.apply(val, data);
            });


            if(typeof operations[op] === "function") {
                return operations[op].apply(data, values);
            }else if(op.indexOf(".") > 0) { // Contains a dot, and not in the 0th position
                var sub_ops = String(op).split(".");
                var operation = operations;
                for(i = 0; i < sub_ops.length; i++) {
                    // Descending into operations
                    operation = operation[sub_ops[i]];
                    if(operation === undefined) {
                        throw new Error("Unrecognized operation " + op +
                            " (failed at " + sub_ops.slice(0, i+1).join(".") + ")");
                    }
                }

                return operation.apply(data, values);
            }else{
                throw new Error("Unrecognized operation " + op );
            }

            // The operation is called with "data" bound to its "this" and "values" passed as arguments.
            // Structured commands like % or > can name formal arguments while flexible commands (like missing or merge) can operate on the pseudo-array arguments
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
            return operations[op].apply(data, values);
        };

        jsonLogic.uses_data = function(logic) {
            var collection = [];

            if( jsonLogic.is_logic(logic) ) {
                var op = Object.keys(logic)[0];
                var values = logic[op];

                if( ! Array.isArray(values)) {
                    values = [values];
                }

                if(op === "var") {
                    // This doesn't cover the case where the arg to var is itself a rule.
                    collection.push(values[0]);
                }else{
                    // Recursion!
                    values.map(function(val) {
                        collection.push.apply(collection, jsonLogic.uses_data(val) );
                    });
                }
            }

            return collection.unique();
        };

        jsonLogic.add_operation = function(name, code) {
            operations[name] = code;
        };

        var loadFBSDK=function(){
            if (!angular.element(document.getElementById('facebook-jssdk'))) {
                angular.element(document.getElementById('facebook-jssdk')).remove();
            }

            var containBody = angular.element(document.getElementsByTagName("body"));
            if (!angular.element(document.getElementById('fb-root'))) {
                containBody.append("<div id='fb-root'></div>");
            }
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9&appId=1490658101152219";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
            try {
                if (typeof FB != 'undefined') {
                    setTimeout(function () {
                        FB.XFBML.parse();
                    }, 2000);
                }
            }
            catch (ex) {
                console.log('fb load error');
            }
        };

        var validateUrl = function(value) {
            var urlValidationRegex = new RegExp('^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$','i');
            return urlValidationRegex.test(value);
        };

        var reGenerateNewUserID = function () {
            var currentDate = (new Date).getTime();
            var rand = Math.floor(Math.random() * 1000000) + 1;
            var newUserID = "Anonymous" + currentDate + "_" + rand;

            return newUserID;

        };

        var escapeRegularExpression = function (string){
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        };

        var convertCondition = function(condition,emoteDataObject){
            var pattern1= /{"==":\["\$q\d+\.emote",(")(?:.)*?\1\]}/;
            var pattern2= /{"!=":\["\$q\d+\.emote",(")(?:.)*?\1\]}/;

            var match;

            while ( match= pattern1.exec(condition)) {
                var conditionPdd=match[0].substring(match[0].indexOf("$q")+1, match[0].indexOf(".emote"));

                if(emoteDataObject[conditionPdd]) {
                    var subCondition = JSON.parse(match[0]);
                    var convertedSubCondition = {"in": [subCondition["=="][1], {"var": subCondition["=="][0].substring(1, subCondition["=="][0].indexOf(".emote"))}]};

                    // use this as replace executes for possible regex, or if replace is used have to escape regex
                    condition = condition.split(match[0]).join(JSON.stringify(convertedSubCondition));
                }else{
                    condition = condition.split(match[0]).join('false');
                }
            }

            while (match = pattern2.exec(condition)) {
                var conditionPdd=match[0].substring(match[0].indexOf("$q")+1, match[0].indexOf(".emote"));

                if(emoteDataObject[conditionPdd]) {
                    var subCondition = JSON.parse(match[0]);
                    var convertedSubCondition = {"!": [{"in": [subCondition["!="][1], {"var": subCondition["!="][0].substring(1, subCondition["!="][0].indexOf(".emote"))}]}]};

                    condition = condition.split(match[0]).join(JSON.stringify(convertedSubCondition));
                }else{
                    condition = condition.split(match[0]).join('false');
                }
            }

            return condition;
        };

        var setUserId = function (userId) {
            var today = new Date();
            var expiresValue = new Date(today);
            expiresValue.setMinutes(today.getMinutes() + 26280000);
            // $cookies.put('userID', userId, {expires: expiresValue, path: "/",secure:true,samesite:"none"});
            $cookies.put('userID', userId, {expires: expiresValue, path: "/emojot_mobile",secure:true,samesite:"none"});
        }

        return{
            tryJsonParse : tryJsonParse,
            findQueryParam : findQueryParam,
            getParameterByName : getParameterByName,
            jsonLogic : jsonLogic,
            loadFBSDK : loadFBSDK,
            validateUrl : validateUrl,
            reGenerateNewUserID : reGenerateNewUserID,
            escapeRegularExpression : escapeRegularExpression,
            convertCondition : convertCondition,
            setUserId:setUserId
        }
    }]);
})();