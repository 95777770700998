(function () {
    angular.module('mobileApp').service('triggerStateChangeService', ['$rootScope', '$window', '$http', '$cookies', 'sensorUpdateService', 'alertGeneratorService', 'utilityProviderService', function ($rootScope, $window, $http, $cookies, sensorUpdateService, alertGeneratorService, utilityProviderService) {

        function escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\?\.\^\$\|]/g, "\\$&");
        };

        function triggerStateChange(triggerType, triggerData) {
            try {
                if ("true" == $window.stateBasedSensorLoaded) {
                    var statesCookieString = $cookies.get("states");

                    statesCookieString = decodeURIComponent(statesCookieString);

                    while (statesCookieString.indexOf("\\\"") != -1) {
                        statesCookieString = statesCookieString.replace("\\\"", "\"");
                    }

                    if(statesCookieString.indexOf("\"{")>-1&&statesCookieString.indexOf("}\"")>-1) {
                        statesCookieString = statesCookieString.substring(1, statesCookieString.length - 1);
                    }
                    $window.clientSideStateSet = JSON.parse(statesCookieString);

                }
            } catch (e) {
                console.log(e);
            }

            //Client side stored state configs found
            if (typeof $window.clientSideStateSet != "undefined") {
                var matchingStateConfig;
                //for (var i = 0; i < $window.clientSideStateSet.length; i++) {
                //
                //    //Client side stored state configs check for matching config
                //    if (typeof $window.initUrl != "undefiend" && $window.initUrl != "null" && $window.initUrl != null && $window.initUrl == $window.clientSideStateSet[i].initUrl) {
                //        matchingStateConfig = $window.clientSideStateSet[i];
                //        break;
                //    }
                //}

                if ($window.clientSideStateSet.hasOwnProperty($window.initUrl)) {
                    matchingStateConfig = $window.clientSideStateSet[$window.initUrl];
                }

                //Client side stored matching state configs found
                if (typeof matchingStateConfig != "undefined" && typeof $window.stateSetConfig != "undefined") {
                    var matchingState;
                    for (var i = 0; i < $window.stateSetConfig.length; i++) {
                        //Matching state find for current user state
                        if (matchingStateConfig.currentState == $window.stateSetConfig[i].stateID) {
                            matchingState = $window.stateSetConfig[i];
                        }
                    }

                    if (typeof matchingState != "undefined") {
                        //Matching state found for current user state
                        var matchingTransitions = [];
                        if (typeof matchingState.transitions != "undefined") {
                            for (var i = 0; i < matchingState.transitions.length; i++) {

                                //Find the matching transition for the trigger type
                                if (triggerType == matchingState.transitions[i].trigger) {
                                    matchingTransitions.push(matchingState.transitions[i]);
                                }
                            }
                        }

                        console.log("Founded Matching Transitions length -" + matchingTransitions.length);

                        if (matchingTransitions.length > 0) {
                            //Processing the matching transition for the trigger type
                            for (var i = 0; i < matchingTransitions.length; i++) {
                                var conditionMet = false;

                                var condition = matchingTransitions[i].condition;
                                var emoteDataObject={};

                                for (var j=0;j<triggerData.length;j++) {
                                        if(emoteDataObject[triggerData[j].percepticDrillDown]){
                                            emoteDataObject[triggerData[j].percepticDrillDown].push(triggerData[j].emot);

                                        }else {
                                            emoteDataObject[triggerData[j].percepticDrillDown] = [triggerData[j].emot];
                                        }
                                }

                                var convertedCondition=convertCondition(condition,emoteDataObject);

                                conditionMet=utilityProviderService.jsonLogic.apply(utilityProviderService.tryJsonParse(convertedCondition),emoteDataObject);

                                if (conditionMet) {
                                    var transitionAction = matchingTransitions[i].transitionAction;

                                    //Get the transition action to process
                                    if ("stateChange" == transitionAction.type) {
                                        var destinationState = transitionAction.destinationState;

                                        //Finding the next state of the state transition
                                        for (var i = 0; i < $window.stateSetConfig.length; i++) {
                                            if (destinationState == $window.stateSetConfig[i].stateID) {
                                                matchingStateConfig.currentState = destinationState;
                                                updateUserStatus($window.initUrl, destinationState);

                                                $window.urlV = 'https://sensorapi.emojot.com/v1/activity?companyID=' + $window.stateSetConfig[i].companyID + "&activityID=" + $window.stateSetConfig[i].sensorID;
                                                var destinationStateSensorData = $window.cachedRedirectSensorObjects[$window.stateSetConfig[i].companyID + '_' + $window.stateSetConfig[i].sensorID];
                                                sensorUpdateService.updateNewActivityData(destinationStateSensorData);

                                                var today = new Date();
                                                var expiresValue = new Date(today);
                                                expiresValue.setMinutes(today.getMinutes() + 26280000);

                                                $cookies.put("states", JSON.stringify($window.clientSideStateSet), {
                                                    "path": "/",
                                                    "expires": expiresValue,
                                                    secure:true,samesite:"none"
                                                });
                                                break;
                                            }
                                        }
                                    } else if ("bannerDisplay" == transitionAction.type) {
                                        $rootScope.isStateBanner = true;
                                        var stateActionObject = {};

                                        stateActionObject["type"] = ["advertisementBanner"];
                                        stateActionObject["data"] = [{
                                            "imageUrl": [transitionAction.bannerUrl],
                                            "imageLink": [transitionAction.bannerHref]
                                        }];

                                        $rootScope.$broadcast('State engagement trigger', {
                                            stateAction: stateActionObject
                                        });
                                    } else if ("redirect" == transitionAction.type) {
                                        var redirectURL = transitionAction.redirectURL;
                                        var emoSigObj = {};
                                        if($window.emoSignature){
                                            emoSigObj = angular.copy($window.emoSignature);
                                        }

                                        if($rootScope.embeddedEmoSignatureDataForUser){
                                            for(var key in $rootScope.embeddedEmoSignatureDataForUser){
                                                if($rootScope.embeddedEmoSignatureDataForUser.hasOwnProperty(key)){
                                                    if(!emoSigObj[key] || $rootScope.emoSignatureDataPriority == "User"){
                                                        emoSigObj[key] = $rootScope.embeddedEmoSignatureDataForUser[key];
                                                    }
                                                }
                                            }
                                        }
                                        var ehString = "";
                                        for(var key in emoSigObj){
                                            if(emoSigObj.hasOwnProperty(key)){
                                                ehString += (key + "~~" + emoSigObj[key] +"__");
                                            }
                                        }
                                        if(ehString){
                                            ehString = ehString.substring(0, ehString.length - 2);
                                            redirectURL+="?emoSignature="+ encodeURIComponent(urlEncode(ehString));
                                        }

                                        if($window.language){
                                            if(redirectURL.indexOf("?")==-1){
                                                redirectURL+="?lng="+$window.language;
                                            }else{
                                                redirectURL+="&lng="+$window.language;
                                            }
                                        }

                                        if(transitionAction.openNewTab){
                                            $window.open(redirectURL, '_blank');
                                            setTimeout(function () {
                                                location.reload();
                                            }, 4000);
                                        } else {
                                            setTimeout(function () {
                                                location.href = redirectURL;
                                            }, 4000);

                                        }
                                    }

                                    //TODO More types
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        function remapState(newState) {
            try {
                if ("true" == $window.stateBasedSensorLoaded) {
                    var statesCookieString = $cookies.get("states");

                    statesCookieString = decodeURIComponent(statesCookieString);

                    while (statesCookieString.indexOf("\\\"") != -1) {
                        statesCookieString = statesCookieString.replace("\\\"", "\"");
                    }

                    if(statesCookieString.indexOf("\"{")>-1&&statesCookieString.indexOf("}\"")>-1) {
                        statesCookieString = statesCookieString.substring(1, statesCookieString.length - 1);
                    }

                    $window.clientSideStateSet = JSON.parse(statesCookieString);

                }
            } catch (e) {
                console.log(e);
            }

            //Client side stored state configs found
            if (typeof $window.clientSideStateSet != "undefined") {
                var matchingStateConfig;

                if ($window.clientSideStateSet.hasOwnProperty($window.initUrl)) {
                    matchingStateConfig = $window.clientSideStateSet[$window.initUrl];
                }

                //Client side stored matching state configs found
                if (typeof matchingStateConfig != "undefined" && typeof $window.stateSetConfig != "undefined") {


                    if (matchingStateConfig.currentState != newState) {

                        //Finding the next state of the state transition
                        for (var i = 0; i < $window.stateSetConfig.length; i++) {
                            if (newState == $window.stateSetConfig[i].stateID) {
                                matchingStateConfig.currentState = newState;

                                $window.urlV = 'https://sensorapi.emojot.com/v1/activity?companyID=' + $window.stateSetConfig[i].companyID + "&activityID=" + $window.stateSetConfig[i].sensorID;
                                var destinationStateSensorData = $window.cachedRedirectSensorObjects[$window.stateSetConfig[i].companyID + '_' + $window.stateSetConfig[i].sensorID];
                                sensorUpdateService.updateNewActivityData(destinationStateSensorData);

                                var today = new Date();
                                var expiresValue = new Date(today);
                                expiresValue.setMinutes(today.getMinutes() + 26280000);

                                $cookies.put("states", JSON.stringify($window.clientSideStateSet), {
                                    "path": "/",
                                    "expires": expiresValue,
                                     secure:true,
                                     samesite:"none"
                                });
                                break;
                            }
                        }

                    }
                }
            }
        }

        function updateUserStatus(initUrl, newState) {
            var updateStatusUrl = "https://sensorapi.emojot.com/v1/userState";
            var updateDate = {
                initUrl: initUrl,
                state: newState
            }

            $http({
                method: 'PUT',
                url: updateStatusUrl,
                data: JSON.stringify(updateDate),
                contentType: 'application/json',
                headers: {'x-api-key': $rootScope.sensorAPIKey}
            }).then(function (status) {
                console.log("User status updated -" + JSON.stringify(status));
            }, function (status) {
                alertGeneratorService.addAlert({
                    type: 'danger',
                    msg: 'Your login session has expired. Please login again!'
                });
            });
        }

        function addUserStatus(initUrl, state,callback) {
            var addStatusUrl = "https://sensorapi.emojot.com/v1/userState";

            var addDate = {
                initUrl: initUrl,
                state: state,
                userID: $rootScope.userID
            }

            $http({
                method: 'POST',
                url: addStatusUrl,
                data: JSON.stringify(addDate),
                contentType: 'application/json',
                headers: {'x-api-key': $rootScope.sensorAPIKey}
            }).then(function (status) {
                console.log("User status added -" + JSON.stringify(status));
                callback();
            }, function (status) {
                alertGeneratorService.addAlert({
                    type: 'danger',
                    msg: 'Your login session has expired. Please login again!'
                });
                callback();
            });
        }

        function convertCondition(condition,emoteDataObject){
            var pattern1= /{"==":\["\$q\d+\.emote",(")(?:.)*?\1\]}/;
            var pattern2= /{"!=":\["\$q\d+\.emote",(")(?:.)*?\1\]}/;

            var match;

            while ( match= pattern1.exec(condition)) {
                var conditionPdd=match[0].substring(match[0].indexOf("$q")+1, match[0].indexOf(".emote"));

                if(emoteDataObject[conditionPdd]) {
                    var subCondition = JSON.parse(match[0]);
                    var convertedSubCondition = {"in": [subCondition["=="][1], {"var": subCondition["=="][0].substring(1, subCondition["=="][0].indexOf(".emote"))}]};

                    // use this as replace executes for possible regex, or if replace is used have to escape regex
                    condition = condition.split(match[0]).join(JSON.stringify(convertedSubCondition));
                }else{
                    condition = condition.split(match[0]).join('false');
                }
            }

            while (match = pattern2.exec(condition)) {
                var conditionPdd=match[0].substring(match[0].indexOf("$q")+1, match[0].indexOf(".emote"));

                if(emoteDataObject[conditionPdd]) {
                    var subCondition = JSON.parse(match[0]);
                    var convertedSubCondition = {"!": [{"in": [subCondition["!="][1], {"var": subCondition["!="][0].substring(1, subCondition["!="][0].indexOf(".emote"))}]}]};

                    condition = condition.split(match[0]).join(JSON.stringify(convertedSubCondition));
                }else{
                    condition = condition.split(match[0]).join('false');
                }
            }

            return condition;
        };

        function urlEncode(unencoded) {
            var encoded = Base64.encode(unencoded);
            return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        };
        function urlDecode(encoded) {
            encoded = encoded.replace(/-/g, '+').replace(/_/g, '/');
            while (encoded.length % 4)
                encoded += '=';
            return Base64.decode(encoded);
        }
        return {
            triggerStateChange: triggerStateChange,
            updateUserStatus: updateUserStatus,
            remapState:remapState,
            addUserStatus:addUserStatus,
            escapeRegExp:escapeRegExp
        }

    }]);
})();