(function () {
    angular.module('mobileApp').service('reverseGeoCodeService', ['$rootScope', '$http', '$cookies',
        function ($rootScope, $http, $cookies) {
            var reverseGeo = function (lat, lon, callback) {
                var server = 'https://open.mapquestapi.com/nominatim/v1/reverse.php';
                $http({
                    method: 'GET',
                    url: server + '/reverse?key=uTMY2zUqobRInEeB50pxaI46bM4xl7nY&format=json&lat=' + lat + '&lon=' + lon,
                }).then(function (status) {
                    if (status.status === 4 || status.status == 200) {
                        // console.log(status.data.address);
                        callback(status.data.address);
                    }
                }, function (status) {
                    // console.log(status);
                });
            };

            var getLocation = function (fn) {
                if(window.globalIpDetails){
                    processIpDetails(window.globalIpDetails,fn)
                } else{
                    $http({
                        method: 'GET',
                        url: 'https://api.ipstack.com/check?access_key=7d41b5706c6d37243f878e3d4bb5186a'
                    }).then(function (ipStackResult) {
                        var userLocation = 'user location';
                        if(ipStackResult.data){
                            window.globalIpDetails = ipStackResult.data
                            processIpDetails(ipStackResult.data,fn);
                        } else{
                            fn(null);
                        }
                    }, function (status) {
                        if (status.status == 403) {
                            $cookies.putObject('location', null,{secure:true,samesite:"none"});
                        }
                        fn(null);
                    }).catch(function(err) {
                        console.log("Geo Location Detection Error -"+err);
                        fn(null);
                    });
                }
            };

            var processIpDetails = function (ipStackResult,fn) {
                if (ipStackResult) {
                    if ($rootScope.advancedGeoLocationEnabled != "undefined" && ($rootScope.advancedGeoLocationEnabled == true || $rootScope.advancedGeoLocationEnabled == "true")) {
                        if (navigator.geolocation&&navigator.geolocation.getCurrentPosition) {
                            navigator.geolocation.getCurrentPosition(function (pos) {

                                // gpsLocationFound = true;

                                var gpsLocation = {
                                    latitude: pos.coords.latitude,
                                    longitude: pos.coords.longitude,
                                    gpsLocation: true
                                };

                                reverseGeo(gpsLocation.latitude, gpsLocation.longitude, function (response) {
                                    var gpsBasedLocation = {
                                        'ip': ipStackResult.ip,
                                        'country': response.country,
                                        'countryCode':  response.country_code.toUpperCase(),
                                        'zip_code': response.postcode,
                                        'admin_level_1': response.state,
                                        'locality': response.city || response.village,
                                        'locationSource': 'gps',
                                        'type': 'Point',
                                        'longitude': gpsLocation.longitude,
                                        'latitude': gpsLocation.latitude,
                                        'coordinates': [
                                            gpsLocation.longitude,
                                            gpsLocation.latitude
                                        ]
                                    };

                                    $cookies.putObject('location', gpsBasedLocation,{secure:true,samesite:"none"});

                                    fn(gpsBasedLocation);
                                });
                            }, function (err) {
                                if (err.code == 1) {
                                    console.log("Error: Access is denied!");
                                } else if (err.code == 2) {
                                    console.log("Error: Position is unavailable!");
                                } else {
                                    console.log(err);
                                }

                                var location = {
                                    'ip': ipStackResult.ip,
                                    'locality': ipStackResult.locality || ipStackResult.city || "N/A",
                                    'admin_level_1': ipStackResult.region_name,
                                    'country':ipStackResult.country || ipStackResult.country_name || "N/A",
                                    'countryCode': ipStackResult.country_code,
                                    'zip_code': ipStackResult.zip_code,
                                    'locationSource': 'ip',
                                    'type': 'Point',
                                    'longitude': ipStackResult.longitude,
                                    'latitude': ipStackResult.latitude,
                                    'coordinates': [
                                        ipStackResult.longitude,
                                        ipStackResult.latitude
                                    ]
                                };

                                $cookies.putObject('location', location,{secure:true,samesite:"none"});

                                fn(location);
                            });
                        }
                    } else {
                        var location = {
                            'ip': ipStackResult.ip,
                            'locality': ipStackResult.locality || ipStackResult.city || "N/A",
                            'admin_level_1': ipStackResult.region_name,
                            'country':ipStackResult.country || ipStackResult.country_name || "N/A",
                            'countryCode': ipStackResult.country_code,
                            'zip_code': ipStackResult.zip_code,
                            'locationSource': 'ip',
                            'type': 'Point',
                            'longitude': ipStackResult.longitude,
                            'latitude': ipStackResult.latitude,
                            'coordinates': [
                                ipStackResult.longitude,
                                ipStackResult.latitude
                            ]
                        };

                        $cookies.putObject('location', location,{secure:true,samesite:"none"});

                        fn(location);
                    }
                } else {
                    fn(null);
                }
            }

            return {
                getLocation: getLocation
            }
        }]);
})();
