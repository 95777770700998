//this service is for providing utilities for logging
(function () {
    angular.module('mobileApp').service('systemLoggerService', ['$rootScope', '$window', '$cookies', function ($rootScope, $window, $cookies) {

        var setGlobalSensorAttributes = function () {
            $window.activityID = $rootScope.activityID;
            $window.companyID = $rootScope.companyID;

            if (isAnonymous) {
                $window.userID = $cookies.get('userID');
            } else {
                $window.userID = serverSideUserID;
            }

            $window.activityTitle = $rootScope.activityTitle;
        };

        return {
            setGlobalSensorAttributes: setGlobalSensorAttributes
        }
    }]);
})();