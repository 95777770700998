(function () {
    angular.module('mobileApp').service('alertGeneratorService', ['$rootScope', function ($rootScope,triggerType,triggerData) {
        $rootScope.alerts = [];

        $rootScope.alertMessagesDefault = {
            emoteSelectionRequired:"Please make a selection below",
            multiSelectFirstText:"Please select at least",
            multiSelectSecondText:"selections for multi-select question!",
            emoteSuccess:"Emoted Successfully. Thanks for Emoting!",
            multiSelectMaxFirstText : "You cannot select more than",
            multiSelectMaxSecondText : "selections for multi-select question",
            responseRequired:"At least one response is required.",
            mandatoryFieldsRequired:"Please fill out the mandatory field"
        };

        $rootScope.alertMessages = {
            emoteSelectionRequired:"Please make a selection below",
            multiSelectFirstText:"Please select at least",
            multiSelectSecondText:"selections for multi-select question!",
            emoteSuccess:"Emoted Successfully. Thanks for Emoting!",
            multiSelectMaxFirstText : "You cannot select more than",
            multiSelectMaxSecondText : "selections for multi-select question",
            responseRequired:"At least one response is required.",
            mandatoryFieldsRequired:"Please fill out the mandatory field"
        };

        var addAlert = function(alert) {
            $rootScope.alerts = []; // restrict to have only one alert
            $rootScope.alerts.push(alert);
        };

        $rootScope.closeAlert = function(index) {
            $rootScope.alerts.splice(index, 1);
        };

        var closeAllAlerts = function () {
            $rootScope.alerts = [];
        }

        return {
            addAlert : addAlert,
            closeAllAlerts : closeAllAlerts
        }
    }])
})();