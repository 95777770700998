(function () {
    angular.module('mobileApp').service('formValidationService', ['$rootScope','alertGeneratorService', function ($rootScope,alertGeneratorService) {

        var performFormValidation = function (formObject, callback) {
            if (formObject.$valid) {
                callback(true);
            }
            else {
                if (formObject.$error.required) {
                    alertGeneratorService.addAlert({
                        type: 'warning',
                        msg: $rootScope.alertMessages.mandatoryFieldsRequired + " " + formObject.$error.required[0].$$element["0"].id + "!"
                    });
                }
                if (formObject.$error.pattern) {
                    alertGeneratorService.addAlert({
                        type: 'warning',
                        msg: "Please enter valid data for :  " + formObject.$error.pattern[0].$$attr.id + "!"
                    });
                }
                else if (formObject.$error.ngIntlTelInput) {
                    alertGeneratorService.addAlert({
                        type: 'warning',
                        msg: "Please enter a valid telephone number!"
                    });
                }else if (formObject.$error.date) {
                    alertGeneratorService.addAlert({
                        type: 'warning',
                        msg: "Please enter a valid date!"
                    });
                }
                callback(false);
            }
        };

        $rootScope.maxlengthValidation = function(data){

            var amount = 100;
            try {
                if (data.validation) {
                    if (data.validation.maxLength) {
                        amount = data.validation.maxLength;
                    }
                }
            }catch (ex){

            }
            return amount;
        };

        $rootScope.maxNumberValidation = function(data){

            var maxAmount = 100;
            try {
                if (data.validation) {
                    if (data.validation.max) {
                        maxAmount = data.validation.max;
                    }
                }
            }catch (ex){

            }
            return String(maxAmount);
        };

        $rootScope.minNumberValidation = function(data){

            var minAmount = 0;
            try {
                if (data.validation) {
                    if (data.validation.min) {
                        minAmount = data.validation.min;
                    }
                }
            }catch (ex){

            }
            return String(minAmount);
        };

        return {
            performFormValidation: performFormValidation
        }
    }]);

})();