(function () {
    angular.module('mobileApp').service('userDemographicDataService', ['$rootScope', '$cookies', '$http', 'alertGeneratorService',
        function ($rootScope, $cookies, $http, alertGeneratorService) {
            var existingDemographicData = {};

            $rootScope.$on('Loyalty Capture Complete', function () {
                //get demographic data for user (new/remapped)
                checkExistingDemographicData(0);
            });

            var checkExistingDemographicData = function (tries, callback) {
                var requestParams = {
                    userID: $rootScope.userID
                };

                $http({
                    method: 'GET',
                    url: 'https://sensorapi.emojot.com/v1/demographics',
                    params: requestParams,
                    contentType: 'application/json',
                    headers: {'x-api-key': $rootScope.sensorAPIKey}
                }).then(function (response) {
                    if(response && response.data && response.data.demographics && response.data.demographics.length){
                        //for(var k=0; k < response.data.demographics.length; k++) {
                           // existingDemographicData = response.data.demographics[k].demographics;
                            existingDemographicData = response.data.demographics[0].demographics;
                            if (existingDemographicData) {
                                if (existingDemographicData.companyID && existingDemographicData.companyID.length) {
                                    // getDemographicEmoteTheme(0, 0, callback);
                                    $rootScope.existingUserDemographicData = angular.copy(existingDemographicData.companyID);
                                    if ($rootScope.existingUserDemographicData && $rootScope.percepticDrillDowns) {
                                        //set emote theme information
                                        for (var i = 0; i < $rootScope.existingUserDemographicData.length; i++) {
                                            for (var j = 0; j < $rootScope.percepticDrillDowns.length; j++) {
                                                if ($rootScope.existingUserDemographicData[i].themeID === $rootScope.percepticDrillDowns[j].emoteTheme._id) {
                                                    $rootScope.existingUserDemographicData[i]['themeInfo'] = $rootScope.percepticDrillDowns[j].emoteTheme;
                                                    $rootScope.percepticDrillDowns[j]['value'] = $rootScope.existingUserDemographicData[i].value[0];
                                                    break;
                                                }
                                            }
                                        }

                                        //remove demographics which are not available for the sensor
                                        for (var i = 0; i < existingDemographicData.companyID.length; i++) {
                                            for (var j = 0; j < $rootScope.existingUserDemographicData.length; j++) {
                                                if (existingDemographicData.companyID[i]['themeID'] === $rootScope.existingUserDemographicData[j]['themeID'] && !$rootScope.existingUserDemographicData[j]['themeInfo']) {
                                                    $rootScope.existingUserDemographicData.splice(j, 1);
                                                    break;
                                                }
                                            }
                                        }

                                        //Remove variable if the lenght is empty
                                        if($rootScope.existingUserDemographicData.length==0){
                                            delete $rootScope.existingUserDemographicData;
                                        }
                                    }
                                }
                            }
                        //}
                        if(callback) {
                            callback(true);
                        }
                    }
                }, function (status) {

                });
            };

           /* function getDemographicEmoteTheme(demographicIndex, tries, callback) {
                if(demographicIndex < existingDemographicData.companyID.length) {
                    var requestParams = {
                        themeID: existingDemographicData.companyID[demographicIndex].themeID,
                        companyID: $rootScope.companyID
                    };

                    $http({
                        method: 'GET',
                        url: emojotAPI + '/api/1.0.0/theme',
                        params: requestParams,
                        contentType: 'application/json',
                        headers: {'authorization': "Bearer " + $cookies.get('access_token')}
                    }).then(function (response) {
                        if(response.data && response.data[0]) {
                            existingDemographicData.companyID[demographicIndex].themeInfo = response.data[0];
                            demographicIndex++;
                            getDemographicEmoteTheme(demographicIndex, 0, callback);
                        }
                    }, function (status) {
                        callback(true);
                    });
                }
                else{
                    $rootScope.existingUserDemographicData = existingDemographicData.companyID;
                    console.log($rootScope.existingUserDemographicData);
                    callback(true);
                }
            }*/

            var updateDemographicsData = function (demographicDataArray, demographicDataIndex, method, tries, callback) {
                var errorOccurred = false;
                if(demographicDataIndex < demographicDataArray.length) {

                    var dataObj = {
                        userID: $rootScope.userID,
                        companyID: $rootScope.companyID,
                        demographicObject: demographicDataArray[demographicDataIndex]
                    };

                    $http({
                        method: method,
                        url: 'https://sensorapi.emojot.com/v1/demographics',
                        data: dataObj,
                        contentType: 'application/json',
                        headers:  {'x-api-key': $rootScope.sensorAPIKey}
                    }).then(function (response) {
                        demographicDataIndex++;
                        //after first entry success all should be PUT
                        if(method === 'POST'){
                            method = 'PUT';
                        }
                        updateDemographicsData(demographicDataArray,demographicDataIndex, method, 0, callback);
                    }, function (status) {
                        errorOccurred = true;
                        demographicDataIndex++;
                        updateDemographicsData(demographicDataArray, demographicDataIndex, method, 0, callback);
                    });
                }
                else{
                    if(!errorOccurred) {
                        callback(true);
                    }
                    else{
                        callback(false);
                    }
                }
            };

            $rootScope.setUserDemographicData = function () {
                var demographicDataArray = [];
                $rootScope.actionInProgress = true;

               for(var i=0; i<$rootScope.existingUserDemographicData.length; i++){
                   demographicDataArray.push({
                       drillDownText : $rootScope.existingUserDemographicData[i].parameter,
                       themeID: $rootScope.existingUserDemographicData[i].themeID,
                       value: $rootScope.existingUserDemographicData[i].value
                   });
               }
                updateDemographicsData(demographicDataArray, 0, 'PUT', 0, function (success) {
                    $rootScope.actionInProgress = false;
                    if(success) {
                        alertGeneratorService.addAlert({
                            type: 'success',
                            msg: 'User demographic data updated successfully.'
                        });
                    }
                    else{
                        alertGeneratorService.addAlert({
                            type: 'danger',
                            msg: 'Error occurred while updating demographic data. Try again later!'
                        });
                    }
                });
            };

            //get user demographic data on sensor load
            //checkExistingDemographicData(0);

            return{
                checkExistingDemographicData : checkExistingDemographicData,
                updateDemographicsData : updateDemographicsData
        }
        }]);
})();